import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { StripeElements } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import { LoadingButton } from '@mui/lab';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Alert, Stack, DialogContent } from '@mui/material';

import i18n from 'src/locales/i18n';
import { useTranslate } from 'src/locales';
import {
  useCreatePaymentMethodMutation,
  useInitiatePaymentMethodSetupMutation,
} from 'src/services/billing/billing.service';

import { BusinessErrorCodes } from 'src/types/business-errors';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  onClose: VoidFunction;
}

const getIntentErrorMessage = (e: any) => {
  if (
    (e.data && e.data.error_code === BusinessErrorCodes.PaymentMethodError) ||
    e.code === 'card_declined'
  ) {
    return i18n.t('billing.api.card.create.intent.declined');
  }

  return i18n.t('billing.api.card.create.intent.default_error');
};

export default function PaymentNewCardDialog({ onClose, ...other }: Props) {
  const { t } = useTranslate();

  const { enqueueSnackbar } = useSnackbar();

  const stripe = useStripe();
  const elements = useElements() as StripeElements;

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<string | null>(null);

  const [getSetupIntent] = useInitiatePaymentMethodSetupMutation();

  const [createPaymentMethod] = useCreatePaymentMethodMutation();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setError(null);

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      enqueueSnackbar(getIntentErrorMessage(submitError), { variant: 'error' });

      setError(getIntentErrorMessage(submitError));

      setLoading(false);
      onClose();

      return;
    }

    // Create the SetupIntent and obtain clientSecret
    let res;
    try {
      res = await getSetupIntent().unwrap();
    } catch (e) {
      console.error(e);

      setError(getIntentErrorMessage(e));

      enqueueSnackbar(getIntentErrorMessage(e), { variant: 'error' });

      setLoading(false);
      onClose();

      return;
    }

    const { client_secret: clientSecret } = res;

    confirmCardSetup(clientSecret);
  };

  const confirmCardSetup = async (clientSecret: string) => {
    if (!stripe) return;

    const url = window.location.href;

    // Confirm the SetupIntent using the details collected by the Payment Element
    try {
      const confirmation = await stripe.confirmSetup({
        elements,
        clientSecret,
        redirect: 'if_required',
        confirmParams: {
          return_url: url,
        },
      });

      if (confirmation.error) {
        enqueueSnackbar(getIntentErrorMessage(confirmation.error), { variant: 'error' });
        setError(getIntentErrorMessage(confirmation.error));
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.

        await createPaymentMethod({
          payment_method: confirmation.setupIntent.payment_method as string,
        }).unwrap();

        enqueueSnackbar(t('billing.api.card.create.confirm_setup.success'), { variant: 'success' });
      }
    } catch (e) {
      console.error(e);

      setError(t('billing.api.card.create.intent.default_error'));

      enqueueSnackbar(t('billing.api.card.create.intent.default_error'), { variant: 'error' });
    }

    setLoading(false);
    onClose();
  };

  return (
    <Dialog maxWidth="md" onClose={onClose} {...other}>
      <DialogTitle>{t('billing.payment.new_card')}</DialogTitle>

      <DialogContent>
        <Stack direction="column" gap={1}>
          {error && <Alert severity="error">{error}</Alert>}

          <form onSubmit={handleSubmit}>
            <Stack sx={{ paddingX: 4, paddingY: 3 }}>
              <PaymentElement />
              <LoadingButton
                sx={{ marginTop: 3 }}
                variant="contained"
                type="submit"
                disabled={!stripe}
                loading={loading}
              >
                {t('common.submit')}
              </LoadingButton>
            </Stack>
          </form>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
