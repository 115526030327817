
import Card from '@mui/material/Card';
import Grid from '@mui/system/Unstable_Grid';
import { Stack, Button } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useGetMyProfileQuery } from 'src/services/candidates/candidates.service';

import AddRefereeModal from 'src/sections/profile/references/modal/add-referee-modal';
import ProfileRefereesTable from 'src/sections/profile/references/referees-table/profile-referees-table';

import { TenantType } from 'src/types/enums';

type Props = {
  addEnabled?: boolean;
};

export default function CandidateProfileRefereeListView({ addEnabled = true }: Props) {

  const tenantType = useOrgTenant();

  const { t } = useTranslate();

  const addRefereeModalControl = useBoolean();

  const { currentData: profileData } = useGetMyProfileQuery(undefined, {
    skip: tenantType !== TenantType.Candidate,
  });

  return (
    <Grid container spacing={3}>
      {
        addEnabled && (
          <Grid xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <Button variant="contained" disabled={!profileData?.id} onClick={addRefereeModalControl.onTrue}>
                {t('profile.referees.add_referee')}
              </Button>
            </Stack>
          </Grid>
        )
      }
      <Grid xs={12}>
        <Card>
          <ProfileRefereesTable profileId={profileData?.id} />
        </Card>
      </Grid>

      {
        addRefereeModalControl.value && (
          <AddRefereeModal
            open={addRefereeModalControl.value}
            onClose={addRefereeModalControl.onFalse}
            profileId={profileData?.id as string}
          />
        )
      }

    </Grid>
  );
}
