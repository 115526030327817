import { ISharedJob } from "src/services/jobs/jobs.types";
import { Application } from "src/services/applications/applications.types";
import { Organization, ProfileOrganization } from "src/services/auth/auth.types";
import { IWorkExperience, ICandidateReferee, UpdateProfileRefereeDto, IMinimalCandidateProfile, ReferenceSubmissionResponse } from "src/services/candidates/candidates.types";

export enum ReferenceRequestStatus {
  PENDING_APPROVAL = 'pending_approval',
  PENDING_REFEREES = 'pending_referees',
  SENT = 'sent',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  REJECTED = 'rejected'
}

export interface RequestQuestionDTO {

  question: string;

  required: boolean;

}

export interface CreateReferenceRequestDTO {

  referee_count: number;

  notes: string;

  questions: RequestQuestionDTO[];
}

export interface SubmitReferenceRefereeDTO {

  first_name: string;

  last_name: string;

  email: string;

  phone: string;

  relationship: string;

  position_title: string;

  company_name: string;

  referee_position: string;

  work_experience_id?: string;

}

export enum ReferenceReviewAction {
  APPROVE = 'approve',
  REJECT = 'reject'
}

export enum ReferenceOpenStatus {
  VIEWED = 'viewed',
  NOT_VIEWED = 'not_viewed',
}

export interface ReviewReferenceRequestDTO {

  action: ReferenceReviewAction;

  questions?: RequestQuestionDTO[];

  notes?: string;

}

export interface CompleteReferenceSubmissionDto {

  share_with_candidate: boolean;

  experience_verified: boolean;

  submissions: ReferenceAnswerDto[];

  referee_details: UpdateProfileRefereeDto;

}

export interface ReferenceAnswerDto {

  question_id: string;

  answer: string;

}

export interface ReferenceRequestGroupResponse {

  id: string;

  application: Application;

  managed_by: Organization;

}

export interface ReferenceRequestRefereeResponse {

  id: string;

  first_name: string;

  last_name: string;

  email: string;

  phone: string;

  relationship: string;

  position_title: string;

  company_name: string;

  referee_position: string;

  created_at: Date;

}

export interface ReferenceRequestQuestion {

  id: string;

  question: string;

  required: boolean;
}

export interface ReferenceRequestManager {

  first_name: string;

  last_name: string;

  email: string;

}

export interface PublicReferenceRequestResponse {

  id: string;

  referee: ReferenceRequestRefereeResponse;

  questions: ReferenceRequestQuestion[];

  manager: ReferenceRequestManager;

  organization: ProfileOrganization;

  candidate: IMinimalCandidateProfile;

  work_experience?: IWorkExperience;

  job: ISharedJob;
}

export interface ReferenceRequestResponse {

  id: string;

  group: ReferenceRequestGroupResponse;

  status: ReferenceRequestStatus;

  referee: ReferenceRequestRefereeResponse;

  questions: ReferenceRequestQuestion[];

  ip_address?: string;

  created_at: Date;

  notes: string;

  job?: ISharedJob;

}

export interface ApplicationReferenceResponse {

  id: string;

  referee: ICandidateReferee;

  submissions: ReferenceSubmissionResponse[];

  created_at: Date;

  ip_address: string;

  open_status: ReferenceOpenStatus;

  submitted_at: Date;

}