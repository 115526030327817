import * as Yup from 'yup';
import { useEffect } from 'react';
import { isValid } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';

import { Box, Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Card,
  Grid,
  Button,
  useTheme,
  CardHeader,
  IconButton,
  Typography,
  CardContent
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import {
  useGetReferenceTemplateSettingsQuery,
  useUpdateReferenceTemplateSettingsMutation
} from 'src/services/organisation/organisation.service';

import Iconify from 'src/components/iconify';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';


export default function ReferenceSettingsCard() {

  const { t } = useTranslate();
  const theme = useTheme();

  const { organization } = useAuthContext();

  const { currentData: currentQuestions, isLoading: isLoadingQuestions } = useGetReferenceTemplateSettingsQuery(organization.id);

  const [updateTemplateQuestions, { isLoading: isSubmitting }] = useUpdateReferenceTemplateSettingsMutation();

  const TemplateQuestionsSchema = Yup.object().shape({
    questions: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required(t('validation.required')),
          required: Yup.boolean(),
        })
      )
      .required(t('validation.required'))
      .min(1, t('validation.array_min', { length: 1 }))
      .max(10, t('validation.array_max', { length: 10 })),
  });

  const defaultValues = {
    questions: (Array.isArray(currentQuestions) && currentQuestions.length > 0) ? currentQuestions : []
  };

  const methods = useForm({
    resolver: yupResolver(TemplateQuestionsSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    reset,
    control,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (Array.isArray(currentQuestions) && currentQuestions.length > 0) {
      reset({
        questions: currentQuestions
      });
    }
  }, [currentQuestions, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  if (isLoadingQuestions) {
    return (
      <Box sx={{ width: '100%' }}>
        <Card>
          <CardContent>
            <Typography>{t('common.loading')}</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  const onSubmit = handleSubmit(async (data: any) => {
    try {

      await updateTemplateQuestions({
        organizationId: organization.id,
        questions: data.questions
      }).unwrap();

      enqueueSnackbar(t('admin-organization.settings.references.api.update.success'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t('admin-organization.settings.references.api.update.default_error'), { variant: 'error' });
    }
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Card
        sx={{
          width: '100%',
          position: 'relative',
          boxShadow: 0
        }}
      >
        <CardHeader
          avatar={
            <Iconify
              icon="mdi:frequently-asked-questions"
              sx={{
                width: 30,
                height: 30,
                color: theme.palette.primary.main,
              }}
            />
          }
          title={
            <Typography variant="h6" lineHeight={1.75}>
              {t('admin-organization.settings.references.title')}
            </Typography>
          }
        />

        <CardContent>
          <FormProvider {...methods}>
            <Stack direction="column" justifyContent="center" sx={{ mt: 2 }} gap={2}>
              <Typography variant="body2" color="textSecondary">
                {t('admin-organization.settings.references.description')}
              </Typography>
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                {fields.map((field, index) => (
                  <>
                    <Grid item xs={11} sm={8}>
                      <RHFTextField
                        name={`questions.${index}.question`}
                        label={t('admin-organization.settings.references.labels.question')}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={11} sm={2}>
                      <RHFCheckbox
                        name={`questions.${index}.required`}
                        label={t('admin-organization.settings.references.labels.required')}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <IconButton sx={{ mt: .5 }} onClick={() => remove(index)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </>
                ))}
              </Grid>

              {
                errors.questions?.root && (
                  <Typography variant="caption" color="error">
                    {errors.questions?.root.message}
                  </Typography>
                )
              }

              <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                <Button startIcon={<Iconify icon="gridicons:add-outline" />} variant="contained" color="primary" onClick={() => append({ question: '', required: false })}>
                  {t('admin-organization.settings.references.labels.add_question')}
                </Button>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <LoadingButton
                  type="submit"
                  onClick={onSubmit}
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  {t('common.update')}
                </LoadingButton>
              </Box>
            </Stack>
          </FormProvider>

        </CardContent>
      </Card>
    </Box>
  )
}