import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useGetMyProfileQuery } from 'src/services/candidates/candidates.service';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import { TenantType } from 'src/types/enums';

import CandidateProfileDocumentsView from '../profile/documents/profile-documents';

export default function ProfileDocumentsContainer() {

  const settings = useSettingsContext();

  const authContext = useAuthContext();

  const tenantType = useOrgTenant();

  const { t } = useTranslate();

  const { currentData: profileData } = useGetMyProfileQuery(undefined, {
    skip: tenantType !== TenantType.Candidate,
  });

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading={t('navigation.titles.documents')}
        links={[
          { name: t('navigation.titles.activity'), href: paths.dashboard.root },
          { name: t('navigation.titles.documents'), href: paths.dashboard.profile.documents },
          { name: authContext?.user?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <CandidateProfileDocumentsView profileId={profileData?.id} />
    </Container>
  );
}
