import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';

import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Pagination } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { PageOptions, PaginatedResponse } from 'src/services/api.types';
import {
  ResourceType,
  ResourceRequestStatus,
  ApplicationResourceRequest,
} from 'src/services/applications/applications.types';

import Label from 'src/components/label';
import EmptyContent from 'src/components/empty-content';
import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';

import { TenantType } from 'src/types/enums';

import ResourceRequestActionModal from './resource-request-action-modal';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  target: 'incoming' | 'outgoing';
  requests?: PaginatedResponse<ApplicationResourceRequest>;
  pageOptions: PageOptions;
  onChangePage: (_e: any, page: number) => void;
  profileId: string;
};

export default function ResourceRequestsListModal({
  open,
  onClose,
  target,
  requests,
  pageOptions,
  onChangePage,
  profileId,
}: Props) {
  const { t } = useTranslate();

  const [selectedRequest, setSelectedRequest] = useState<ApplicationResourceRequest | null>(null);

  const tenant = useOrgTenant();

  const getSubtitle = (request: ApplicationResourceRequest) => {
    if (request.type === ResourceType.Document) {
      return request.items.map((item) => t(`enums.document_type.${item.item_type}`)).join(', ');
    }
    if (request.type === ResourceType.ProfileInformation) {
      return request.items
        .map((item) => t(`enums.candidate_profile_section.${item.item_type}`))
        .join(', ');
    }

    return '';
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {target === 'incoming'
          ? t('applications.resource_requests.incoming.title')
          : t('applications.resource_requests.outgoing.title')}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} minWidth={400}>
          {
            requests?.count === 0 ? (
              <Stack justifyContent="center" alignItems="center" sx={{ height: 200 }}>
                <EmptyContent
                  title={t('common.no_requests')}
                />
              </Stack>
            )
              :
              (
                <List sx={{ paddingBottom: 2, minWidth: 430 }}>
                  {requests?.results?.map((request) => (
                    <Fragment key={request.id}>
                      <ListItem
                        secondaryAction={
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Label variant="soft" color="info">
                              {t(`enums.resource_request_status.${request.status}`)}
                            </Label>

                            {tenant === TenantType.Candidate && (
                              <Button
                                variant="contained"
                                size='small'
                                onClick={() => setSelectedRequest(request)}
                                disabled={request.status !== ResourceRequestStatus.PENDING}
                              >
                                {t('common.view')}
                              </Button>
                            )}
                          </Stack>
                        }
                      >
                        <ListItemText
                          primary={`${t(`enums.resource_request_types.${request.type}`)} ${t(
                            'common.request'
                          )}`}
                          secondary={
                            <TruncatedTextField
                              text={getSubtitle(request)}
                              mode="characters"
                              limit={50}
                              variant='caption'
                              showMoreEnabled={false}
                            />
                          }
                        />
                      </ListItem>
                      <Divider />
                    </Fragment>
                  ))}
                </List>
              )
          }
          <Stack flexDirection="row" justifyContent="center" sx={{ py: 0.5 }}>
            <Pagination
              page={pageOptions.page || 1}
              count={Math.ceil((requests?.count || 1) / pageOptions.per_page)}
              onChange={onChangePage}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 1 }}>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>

      {selectedRequest && profileId && (
        <ResourceRequestActionModal
          open={!!selectedRequest}
          onClose={() => setSelectedRequest(null)}
          request={selectedRequest}
          profileId={profileId}
        />
      )}
    </Dialog>
  );
}
