import { FeatureDataType } from 'src/services/billing/billing.types';
import {
  useGetOrgFeaturesQuery,
  useGetFeatureUsageQuery,
} from 'src/services/billing/billing.service';

import { FeatureType } from 'src/types/subscription.types';

export const useFeatures = () => {
  const { currentData: orgFeatures } = useGetOrgFeaturesQuery();
  const { currentData: featureUsages } = useGetFeatureUsageQuery();

  const canAccess = (featureType: FeatureType, quantity?: number): boolean => {
    const orgFeature = orgFeatures?.find((f) => f.feature.type === featureType);

    if (!orgFeature) {
      return false;
    }

    const { feature } = orgFeature;

    switch (feature.data_type) {
      case FeatureDataType.NUMBER: {
        if (quantity === null) {
          throw Error('quantity is required for number type features');
        }

        // unlimited usage
        if (orgFeature.value === null) {
          return true;
        }

        const featureUsage = featureUsages?.find((usage) => usage.feature.type === featureType);

        if (!featureUsage) {
          return false;
        }

        // restricted usage
        return (featureUsage.usage + (quantity as number)) <= (orgFeature.value as number);
      }
      case FeatureDataType.BOOLEAN:
        return orgFeature.value === true;
      default:
        return false;
    }
  }

  return {
    canAccess
  };
};
