import { rootApi } from '../rootApi';
import { APIResponse, PageOptions, HTTPRequestType, PaginatedResponse } from '../api.types';
import {
  ParticpantAccount,
  CreateInterviewDto,
  UpdateInterviewDto,
  ReviewInterviewDto,
  ConfirmInterviewDto,
  ScheduledInterviewResponse,
} from './interview.types';

export const interviewsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getApplicationInterviews: builder.query<PaginatedResponse<ScheduledInterviewResponse>, { applicationId: string; params: PageOptions & { status?: "pending" | "completed" | "scheduled" } }>({
      query: ({ applicationId, params }) => ({
        url: `/jobs/applications/${applicationId}/interviews`,
        params
      }),
      providesTags: ['Interviews'],
    }),

    getPossibleInterviewParticipants: builder.query<ParticpantAccount[], { applicationId: string }>(
      {
        query: ({ applicationId }) => ({
          url: `/jobs/applications/${applicationId}/interviews/available-participants`,
        }),
        providesTags: ['InterviewsParticipants'],
      }
    ),

    scheduleInterview: builder.mutation<ScheduledInterviewResponse, { applicationId: string; data: CreateInterviewDto }>({
      query: ({ applicationId, data }) => ({
        url: `/jobs/applications/${applicationId}/interviews`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Interviews', 'InterviewsParticipants'],
    }),

    updateInterview: builder.mutation<APIResponse, { interviewId: string; data: UpdateInterviewDto }>({
      query: ({ interviewId, data }) => ({
        url: `/jobs/applications/interviews/${interviewId}`,
        method: HTTPRequestType.PUT,
        data,
      }),
      invalidatesTags: ['Interviews', 'InterviewsParticipants'],
    }),

    cancelInterview: builder.mutation<APIResponse, { interviewId: string }>({
      query: ({ interviewId }) => ({
        url: `/jobs/applications/interviews/${interviewId}/cancel`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: ['Interviews', 'InterviewsParticipants'],
    }),

    getInterviewById: builder.query<ScheduledInterviewResponse, { interviewId: string }>({
      query: ({ interviewId }) => ({
        url: `/jobs/applications/interviews/${interviewId}`,
      }),
      providesTags: ['Interviews'],
    }),

    reviewInterview: builder.mutation<APIResponse, { interviewId: string; data: ReviewInterviewDto }>({
      query: ({ interviewId, data }) => ({
        url: `/jobs/applications/interviews/${interviewId}/review`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Interviews', 'InterviewsParticipants'],
    }),

    requestReschedule: builder.mutation<APIResponse, { interviewId: string; data: { notes: string } }>({
      query: ({ interviewId, data }) => ({
        url: `/jobs/applications/interviews/${interviewId}/request-reschedule`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Interviews'],
    }),

    confirmInterview: builder.mutation<APIResponse, { interviewId: string; data: ConfirmInterviewDto }>({
      query: ({ interviewId, data }) => ({
        url: `/jobs/applications/interviews/${interviewId}/confirm`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['Interviews'],
    }),
  }),
});

export const {
  useGetApplicationInterviewsQuery,
  useGetPossibleInterviewParticipantsQuery,
  useScheduleInterviewMutation,
  useGetInterviewByIdQuery,
  useCancelInterviewMutation,
  useUpdateInterviewMutation,
  useReviewInterviewMutation,
  useRequestRescheduleMutation,
  useConfirmInterviewMutation,
} = interviewsApi;
