import { isString } from 'lodash';

import { formatNumber } from 'src/utils/text.utils';

import i18n from 'src/locales/i18n';

import { TenantType } from 'src/types/enums';
import { BusinessErrorCodes } from 'src/types/business-errors';

import { jobsApi } from './jobs.service';
import { IJob, JobFilters } from './jobs.types';
import { ApplicationStage } from '../applications/applications.types';

export const generateAcceptJobLinkRequestError = (e: any) => {

  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.ResourceNotFound) {
      return i18n.t('jobs.action.api.not_found');
    }
    if (e?.data.error_code === BusinessErrorCodes.InvalidLinkRequestState) {
      return i18n.t('jobs.action.api.invalid_state');
    }

    if (e?.data.error_code === BusinessErrorCodes.JobAlreadyLinked) {
      return i18n.t('jobs.action.api.link_exists');
    }

    if (e?.data.error_code === BusinessErrorCodes.RecruitmentTypeRestriction) {
      return i18n.t('jobs.action.api.recruitment_type_restriction');
    }

    if (e?.data.error_code === BusinessErrorCodes.CannotLinkInternalJob) {
      return i18n.t('jobs.action.api.cannot_link_internal');
    }

    if (e?.data.error_code === BusinessErrorCodes.JobIndustryNotApproved) {
      return i18n.t('jobs.action.api.specialization_restriction');
    }

    if (e?.data.error_code === BusinessErrorCodes.DefaultFeeSettingsNotConfigured) {
      return i18n.t('jobs.action.api.missing_default_fee');
    }
  }
  return i18n.t('jobs.action.api.default_error');
};

export const generateRejectJobLinkRequestError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.ResourceNotFound) {
      return i18n.t('jobs.action.api.not_found');
    }

    if (e?.data.error_code === BusinessErrorCodes.InvalidLinkRequestState) {
      return i18n.t('jobs.action.api.invalid_state');
    }
  }
  return i18n.t('jobs.action.api.default_error');
};

export const updateJobApplicationStatus = (
  jobId: string,
  destinationColumnId: string,
  filters: JobFilters
) =>
  jobsApi.util.updateQueryData(
    'getMyActiveJobs',
    Object.values(filters).every((value) => !value) ? undefined : filters,
    (jobsResponse) => ({
      ...jobsResponse,
      results: jobsResponse.results.map((job) =>
        job.id === jobId
          ? { ...job, latest_application_status: destinationColumnId as ApplicationStage }
          : job
      ),
    }),
    true
  );

export const generateJobApplicationStatusUpdateError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.JobHasActiveApplication) {
      return i18n.t('jobs.api.update_stage.is_linked');
    }
  }

  return i18n.t('jobs.api.update_stage.default_error');
};

export const generateArchiveJobError = (e: any, tenant: TenantType) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.JobHasActiveApplication) {
      return tenant === TenantType.Candidate
        ? i18n.t('jobs.api.archive.linked')
        : i18n.t('jobs.api.archive.linked_org');
    }
  }
  return i18n.t('jobs.api.archive.default_error');
};

export const generateCreateJobError = (e: any) => {

  if (e?.status === 403) {
    return i18n.t('exceptions.unauthorized');
  }

  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.WebsiteScrapeFailed) {
      return i18n.t('jobs.api.create.scrape_failed');
    }
  }
  return i18n.t('jobs.api.create.default_error');
};

export const generateInitiateApplyFlowError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.CandidateAlreadyApplied) {
      return i18n.t('jobs.job_board.apply_manager.initiate.api.already_applied');
    }

    if (e?.data.error_code === BusinessErrorCodes.InvalidPhoneNumber) {
      return i18n.t('jobs.job_board.apply_manager.initiate.api.invalid_phone_number');
    }

    if (e?.data.error_code === BusinessErrorCodes.ApplyIntentExists) {
      return i18n.t('jobs.job_board.apply_manager.initiate.api.already_applied');
    }
  }

  return i18n.t('jobs.job_board.apply_manager.initiate.api.default_error');
};

export const generateCandidateInviteError = (e: any) => {

  if (e?.status === 403) {
    return i18n.t('exceptions.unauthorized');
  }

  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.CandidateAlreadyApplied) {
      return i18n.t('jobs.api.invite.already_applied');
    }

    if (e?.data.error_code === BusinessErrorCodes.JobInviteExists) {
      return i18n.t('jobs.api.invite.already_invited');
    }

    if (e?.data.error_code === BusinessErrorCodes.InvalidInviteRecipient) {
      return i18n.t('jobs.api.invite.invalid_recipient');
    }
  }
  return i18n.t('jobs.api.invite.default_error');
};

export const generateJobInviteResendError = (e: any) => {

  if (e?.status === 403) {
    return i18n.t('exceptions.unauthorized');
  }

  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.JobInviteResendLimit) {
      return i18n.t('jobs.pending_invite_modal.api.resend.limit_reached');
    }

    if (e?.data.error_code === BusinessErrorCodes.JobInviteExpired) {
      return i18n.t('jobs.pending_invite_modal.api.resend.expired');
    }
  }

  return i18n.t('jobs.pending_invite_modal.api.resend.default_error');
};

export const formatJobSalary = (job: IJob): string => {
  if (!job.minimum_salary || !job.maximum_salary || !job.currency || !job.rate_period) return 'Salary not specified';

  const period = job.rate_period?.replace('_', ' ');
  if (job.minimum_salary && job.maximum_salary) {
    return `${job.currency?.code} ${formatNumber(job.minimum_salary)} - ${formatNumber(job.maximum_salary)} per ${period}`;
  }
  return `${job.currency?.code} ${formatNumber(job.minimum_salary || job.maximum_salary)} per ${period}`;
};

export const getJobAttributes = (job: IJob): { text: string, icon: string }[] => {
  // format job_type, work_style, employment_type (any of these can be undefined)
  let location;
  if (job?.workplace_locations && job.workplace_locations.length > 0) {
    location = job.workplace_locations[0].address
  }

  const attributes = [
    { key: 'job_type', value: job.job_type, icon: 'duo-icons:briefcase' },
    { key: 'work_style', value: job.work_style, icon: 'duo-icons:world' },
    { key: 'employment_type', value: job.employment_type, icon: 'duo-icons:id-card' },
    { key: 'industry', value: job.industry?.name, icon: 'duo-icons:folder-open' },
    { key: 'salary', value: formatJobSalary(job), icon: 'duo-icons:currency-euro' },
    { key: 'location', value: location, icon: 'duo-icons:location' },
  ];
  return attributes.filter(attr => attr.value).map(attr => ({
    icon: attr.icon,
    text: attr.value?.replace('_', ' '),
  })) as { text: string, icon: string }[];
}
