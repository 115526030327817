
import { Stack } from '@mui/system';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Button, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { ReferenceOpenStatus, ApplicationReferenceResponse } from 'src/services/references/references.types';

import Label from 'src/components/label';
import { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

type Props = {
  row: ApplicationReferenceResponse;
  onViewSubmissions: (referenceId: string) => void;
};

export default function ApplicationReferencesTableRow({
  row,
  onViewSubmissions
}: Props) {

  const confirm = useBoolean();

  const popover = usePopover();

  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  return (
    <TableRow hover>
      <TableCell width={400}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle2">
            {row.referee.first_name} {row.referee.last_name}
          </Typography>

          {
            row.open_status === ReferenceOpenStatus.NOT_VIEWED && (
              <Label color='primary'>{t('common.new')}</Label>
            )
          }

        </Stack>
      </TableCell>
      <TableCell>
        {row.referee.email}
      </TableCell>
      <TableCell>
        {row.referee.phone}
      </TableCell>
      <TableCell>
        {row.referee.company_name}
      </TableCell>

      <TableCell>
        <Button variant="contained" color="primary" onClick={() => onViewSubmissions(row.id)}>
          {t('applications.detail.references.view_submissions')}
        </Button>
      </TableCell>


    </TableRow >
  );
}
