import { Helmet } from 'react-helmet-async';

import ReferenceLandingView from 'src/sections/external/references/reference-landing-view';

// ----------------------------------------------------------------------

export default function ReferenceLandingPage() {

  return (
    <>
      <Helmet>
        <title>JobTetris: Submit Reference</title>
      </Helmet>

      <ReferenceLandingView />
    </>
  );
}
