import { t } from 'i18next';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { Table, TableBody, TableContainer } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { PageOptions } from 'src/services/api.types';
import { useGetReferencesByApplicationQuery } from 'src/services/references/references.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom
} from 'src/components/table';

import ViewApplicationReferenceModal from 'src/sections/jobs/applications/modals/references/view-submissions-modal';
import ApplicationReferencesTableRow from 'src/sections/jobs/applications/detail/application-references-table/application-references-table-row';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: t('profile.referees.table.labels.name'), width: 200 },
  { id: 'email', label: t('profile.referees.table.labels.email') },
  { id: 'phone', label: t('profile.referees.table.labels.phone') },
  { id: 'company', label: t('profile.referees.table.labels.company_name') },
  { id: 'actions', label: t('profile.referees.table.labels.actions') },
];

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 5,
};

type Props = {
  applicationId?: string;
}

// ----------------------------------------------------------------------\

export default function ApplicationReferencesTable({ applicationId }: Props) {

  const table = useTable();

  const { enqueueSnackbar } = useSnackbar();

  const [pageState, setPageState] = useState<PageOptions>(DEFAULT_PAGE);

  const viewSubmissionsControl = useBoolean();

  const [selectedReferenceId, setSelectedReferenceId] = useState<string | null>(null);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const { currentData: applicationReferences } = useGetReferencesByApplicationQuery({
    applicationId: applicationId as string,
    params: {
      ...pageState
    },
  }, {
    skip: !applicationId
  });

  const notFound = !applicationReferences || !applicationReferences.count;

  const handleViewSubmissions = (referenceId: string) => {
    setSelectedReferenceId(referenceId);
    viewSubmissionsControl.onTrue();
  }

  const handleCloseViewSubmissions = () => {
    setSelectedReferenceId(null);
    viewSubmissionsControl.onFalse();
  }

  return (
    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
      <Scrollbar>
        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
          <TableHeadCustom
            order={table.order}
            headLabel={TABLE_HEAD}
            rowCount={applicationReferences?.count}
            numSelected={table.selected.length}
          />

          <TableBody>
            {applicationReferences && applicationReferences.results?.map((row) => (
              <ApplicationReferencesTableRow
                key={row.id}
                row={row}
                onViewSubmissions={handleViewSubmissions}
              />
            ))}

            <TableEmptyRows
              height={denseHeight}
              emptyRows={emptyRows(table.page, table.rowsPerPage, applicationReferences?.count ?? 0)}
            />

            <TableNoData notFound={notFound} />
          </TableBody>
        </Table>
      </Scrollbar>

      {
        viewSubmissionsControl.value && (
          <ViewApplicationReferenceModal
            open={viewSubmissionsControl.value}
            applicationId={applicationId as string}
            referenceId={selectedReferenceId as string}
            onClose={handleCloseViewSubmissions}
          />
        )
      }
    </TableContainer>
  );
}
