
import { ReferenceRequestResponse, CreateReferenceRequestDTO, ReviewReferenceRequestDTO, SubmitReferenceRefereeDTO, ApplicationReferenceResponse, CompleteReferenceSubmissionDto, PublicReferenceRequestResponse } from 'src/services/references/references.types';

import { rootApi } from '../rootApi';
import { APIResponse, PageOptions, HTTPRequestType, PaginatedResponse } from '../api.types';

export const referencesApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({

    getPublicReferenceRequestById: builder.query<PublicReferenceRequestResponse, { referenceRequestId: string }>({
      query: ({ referenceRequestId }) => ({
        url: `/jobs/applications/reference-requests/public/${referenceRequestId}`,
      })
    }),

    getReferenceRequestById: builder.query<ReferenceRequestResponse, { referenceRequestId: string }>({
      query: ({ referenceRequestId }) => ({
        url: `/jobs/applications/reference-requests/${referenceRequestId}`,
      })
    }),

    getReferenceRequestsByApplication: builder.query<PaginatedResponse<ReferenceRequestResponse>, { applicationId: string; status?: 'pending' | 'completed', params: PageOptions }>({
      query: ({ applicationId, params, status = 'pending' }) => ({
        url: `/jobs/applications/${applicationId}/reference-requests`,
        params: {
          ...params,
          status
        },
      }),
      providesTags: (result, error, { applicationId }) => [{ type: 'ApplicationReferenceRequests', id: applicationId }],
    }),

    createReferenceRequest: builder.mutation<APIResponse, { applicationId: string; data: CreateReferenceRequestDTO }>({
      query: ({ applicationId, data }) => ({
        url: `/jobs/applications/${applicationId}/reference-requests`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: (result, error, { applicationId }) => [{ type: 'ApplicationReferenceRequests', id: applicationId }],
    }),
    submitReferees: builder.mutation<APIResponse, { applicationId: string, referenceRequestId: string, data: SubmitReferenceRefereeDTO }>({
      query: ({ applicationId, referenceRequestId, data }) => ({
        url: `/jobs/applications/${applicationId}/reference-requests/${referenceRequestId}/submit-referees`,
        method: HTTPRequestType.POST,
        data
      }),
      invalidatesTags: (result, error, { applicationId }) => [{ type: 'ApplicationReferenceRequests', id: applicationId }, { type: 'ProfileReferees' }],
    }),

    reviewReferenceRequest: builder.mutation<APIResponse, { applicationId: string, referenceRequestId: string, data: ReviewReferenceRequestDTO }>({
      query: ({ applicationId, referenceRequestId, data }) => ({
        url: `/jobs/applications/${applicationId}/reference-requests/${referenceRequestId}/review`,
        method: HTTPRequestType.POST,
        data
      }),
      invalidatesTags: (result, error, { applicationId }) => [{ type: 'ApplicationReferenceRequests', id: applicationId }],
    }),

    completeReferenceSubmission: builder.mutation<APIResponse, { referenceRequestId: string, data: CompleteReferenceSubmissionDto }>({
      query: ({ referenceRequestId, data }) => ({
        url: `/jobs/applications/reference-requests/${referenceRequestId}/complete-submission`,
        method: HTTPRequestType.POST,
        data
      }),
    }),

    getReferencesByApplication: builder.query<PaginatedResponse<ApplicationReferenceResponse>, { applicationId: string; params: PageOptions }>({
      query: ({ applicationId, params }) => ({
        url: `/jobs/applications/${applicationId}/references`,
        params,
      }),
      providesTags: (result, error, { applicationId }) => [{ type: 'ApplicationReferences', id: applicationId }],
    }),
    getApplicationReferenceById: builder.query<ApplicationReferenceResponse, { applicationId: string, referenceId: string }>({
      query: ({ applicationId, referenceId }) => ({
        url: `/jobs/applications/${applicationId}/references/${referenceId}`,
      })
    }),
    markReferenceAsViewed: builder.mutation<APIResponse, { applicationId: string, referenceId: string }>({
      query: ({ applicationId, referenceId }) => ({
        url: `/jobs/applications/${applicationId}/references/${referenceId}/mark-as-viewed`,
        method: HTTPRequestType.POST,
      }),
      invalidatesTags: (result, error, { applicationId }) => [{ type: 'ApplicationReferences', id: applicationId }],
    }),

  }),
});

export const {
  useGetReferenceRequestsByApplicationQuery,
  useGetReferenceRequestByIdQuery,
  useGetPublicReferenceRequestByIdQuery,
  useCreateReferenceRequestMutation,
  useSubmitRefereesMutation,
  useReviewReferenceRequestMutation,
  useCompleteReferenceSubmissionMutation,
  useGetReferencesByApplicationQuery,
  useGetApplicationReferenceByIdQuery,
  useMarkReferenceAsViewedMutation

} = referencesApi;
