import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Tab, Card, Grid, Tabs, alpha, CardHeader, Typography, CardContent } from '@mui/material';

import { useTranslate } from 'src/locales';
import PostCommentForm from 'src/layouts/common/threads/post-comment-form';
import PostCommentList from 'src/layouts/common/threads/post-comment-list';
import { useGetOrganizationCandidateQuery } from 'src/services/organisation/organisation.service';

import CandidateJobsList from 'src/sections/candidates/jobs/jobs-table-view';
import ProfileUser from 'src/sections/profile/profile-sections/profile-user';
import CandidateProfileEditor from 'src/sections/profile/profile-sections/profile-editor';

// ----------------------------------------------------------------------

export default function CandidateDetail() {

  const [selectedTab, onSelectTab] = useState<'profile' | 'jobs' | 'notes'>();

  const { t } = useTranslate();

  const { candidate_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const { currentData: orgCandidate, isLoading } = useGetOrganizationCandidateQuery(
    candidate_id ?? '',
    {
      skip: !candidate_id,
    }
  );

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (!selectedTab) return;

    setSearchParams({ "tab": selectedTab }, { replace: true })

  }, [selectedTab, setSearchParams]);

  useEffect(() => {
    // eslint-disable-next-line no-useless-return
    if (selectedTab) return;

    let defaultTab: string = 'profile';

    const existingTab = searchParams.get('tab');
    if (existingTab) {
      defaultTab = existingTab;
    }

    onSelectTab(defaultTab as 'profile' | 'jobs' | 'notes');

  }, [selectedTab, searchParams]);


  return (
    <Grid container gap={3}>
      <Grid item xs={12}>
        <ProfileUser profile={orgCandidate?.candidate_profile} isLoading={isLoading} mode="view" />
      </Grid>

      <Grid item xs={12}>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => onSelectTab(newValue)}
          sx={{
            px: 2.5,
            mb: { xs: 1, sm: 'unset' },
            boxShadow: (elTheme) => `inset 0 -2px 0 0 ${alpha(elTheme.palette.grey[500], 0.08)}`,
          }}
        >
          <Tab
            key="profile"
            iconPosition="end"
            value="profile"
            label={
              <Typography
                variant="subtitle2"
                sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              >
                {t('candidates.detail.tabs.profile')}
              </Typography>
            }
          />
          <Tab
            key="jobs"
            iconPosition="end"
            value="jobs"
            label={
              <Typography
                variant="subtitle2"
                sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              >
                {t('candidates.detail.tabs.jobs')}
              </Typography>
            }
          />

          <Tab
            key="notes"
            iconPosition="end"
            value="notes"
            label={
              <Typography
                variant="subtitle2"
                sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              >
                {t('candidates.detail.tabs.notes')}
              </Typography>
            }
          />
        </Tabs>
      </Grid>

      {selectedTab === 'profile' && (
        <Grid item xs={12}>
          <CandidateProfileEditor
            profile={orgCandidate?.candidate_profile}
            isLoading={isLoading}
            mode="view"
          />
        </Grid>
      )}

      {selectedTab === 'jobs' && (
        <Grid item xs={12}>
          <CandidateJobsList candidateId={candidate_id as string} />
        </Grid>
      )}

      {selectedTab === 'notes' && (
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('common.notes')} />
            <CardContent>
              <PostCommentForm thread_id={orgCandidate?.thread.id} />
              <PostCommentList thread_id={orgCandidate?.thread.id} />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
