import { t } from 'i18next';
import { useState } from 'react';

import { Table, TableBody, TableContainer } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { PageOptions } from 'src/services/api.types';
import { useGetProfileRefereesQuery } from 'src/services/candidates/candidates.service';

import Scrollbar from 'src/components/scrollbar';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableSkeleton,
  TableEmptyRows,
  TableHeadCustom
} from 'src/components/table';

import ProfileRefereeTableRow from 'src/sections/profile/references/referees-table/profile-referees-table-row';

import RefereeRequestListModal from '../modal/past-referee-requests-modal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: t('profile.referees.table.labels.name'), width: 200 },
  { id: 'email', label: t('profile.referees.table.labels.email') },
  { id: 'phone', label: t('profile.referees.table.labels.phone') },
  { id: 'referee_position', label: t('profile.referees.table.labels.referee_position') },
  { id: 'actions', label: t('profile.referees.table.labels.actions') },
];

const DEFAULT_PAGE: PageOptions = {
  page: 1,
  per_page: 5,
};

type Props = {
  profileId?: string;
}

// ----------------------------------------------------------------------

export default function ProfileRefereesTable({ profileId }: Props) {

  const table = useTable();

  const [pageState, setPageState] = useState(DEFAULT_PAGE);

  const viewRequestsModalControl = useBoolean();

  const [selectedReferee, setSelectedReferee] = useState<string | null>(null);

  const denseHeight = table.dense ? 56 : 56 + 20;

  const { currentData: profileReferees, isLoading: isLoadingReferees } = useGetProfileRefereesQuery({
    profileId: profileId as string,
    pageOptions: pageState,
  }, {
    skip: !profileId
  });

  const notFound = !profileId || !profileReferees || !profileReferees.count;

  const onViewRequests = (refereeId: string) => {
    setSelectedReferee(refereeId);
    viewRequestsModalControl.onTrue();
  }

  const onCloseRequestsModal = () => {
    setSelectedReferee(null);
    viewRequestsModalControl.onFalse();
  }

  if (!profileReferees || isLoadingReferees) {
    return (
      <TableSkeleton />
    )
  }

  return (
    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
      <Scrollbar>
        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
          <TableHeadCustom
            order={table.order}
            headLabel={TABLE_HEAD}
            rowCount={profileReferees?.count}
            numSelected={table.selected.length}
          />

          <TableBody>
            {profileReferees && profileReferees.results?.map((row) => (
              <ProfileRefereeTableRow
                key={row.id}
                onViewRequests={onViewRequests}
                row={row}
              />
            ))}

            <TableEmptyRows
              height={denseHeight}
              emptyRows={emptyRows(table.page, table.rowsPerPage, profileReferees?.count ?? 0)}
            />

            <TableNoData notFound={notFound} />
          </TableBody>
        </Table>
      </Scrollbar>

      {
        viewRequestsModalControl.value && (
          <RefereeRequestListModal
            open={viewRequestsModalControl.value}
            onClose={onCloseRequestsModal}
            profileId={profileId as string}
            refereeId={selectedReferee as string}
          />
        )
      }

    </TableContainer>
  );
}
