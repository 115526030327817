import { Helmet } from 'react-helmet-async';
import { Outlet, useParams } from 'react-router';

import Container from '@mui/material/Container';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useGetMyProfileQuery, useGetProfileRefereeByIdQuery } from 'src/services/candidates/candidates.service';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import { TenantType } from 'src/types/enums';

// ----------------------------------------------------------------------

export default function CandidateProfileRefereesPage() {

  const settings = useSettingsContext();

  const authContext = useAuthContext();

  const tenantType = useOrgTenant();

  const { t } = useTranslate();

  const { referee_id } = useParams();

  const { currentData: profileData } = useGetMyProfileQuery(undefined, {
    skip: tenantType !== TenantType.Candidate,
  });

  const { currentData: refereeData } = useGetProfileRefereeByIdQuery(
    {
      profileId: profileData?.id as string,
      refereeId: referee_id as string,
    },
    { skip: !profileData?.id || !referee_id }
  );

  console.log(refereeData);

  return (
    <>
      <Helmet>
        <title>Dashboard: My Referees</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={t('navigation.titles.referees')}
          links={[
            { name: t('navigation.titles.activity'), href: paths.dashboard.root },
            { name: t('navigation.titles.referees'), href: paths.dashboard.profile.referees.root },
            ...(referee_id
              ? [
                {
                  name: referee_id ? `${refereeData?.first_name} ${refereeData?.last_name}` : 'Loading...',
                  href: referee_id ? paths.dashboard.profile.referees.detail(referee_id) : undefined,
                },
              ]
              : []),
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Outlet />

      </Container>
    </>
  );
}
