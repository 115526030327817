import { useState, useCallback } from 'react';

import { Stack } from '@mui/system';
import {
  Card,
  Button,
  Dialog,
  Pagination,
  Typography,
  CardContent,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useGetRequestSubmissionsQuery } from 'src/services/candidates/candidates.service';


type Props = {
  open: boolean;
  profileId: string;
  refereeId: string;
  requestId: string;
  onClose: VoidFunction;
};

export default function RefereeRequestSubmissionsModal({ open, profileId, refereeId, requestId, onClose }: Props) {
  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const dispatch = useAppDispatch();

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 1,
    per_page: 5,
  });

  const { currentData: requestSubmissions } = useGetRequestSubmissionsQuery({
    profileId,
    refereeId,
    requestId,
    pageOptions
  }, {
    skip: !requestId || !open || !profileId || !refereeId
  });

  const onChangePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t('profile.referees.request_submissions_modal.title')}</DialogTitle>

      <DialogContent>
        <Stack spacing={2} mt={2}>
          {requestSubmissions?.results?.map((submission, index) => (
            <Card
              key={submission.id}
              variant="outlined"
              sx={{
                borderRadius: 1.5
              }}
            >
              <CardContent sx={{ p: 2 }}>
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  sx={{ mb: 1, fontWeight: 'medium' }}
                >
                  {submission.question}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ whiteSpace: 'pre-wrap' }}
                >
                  {submission.answer || t('applications.references.view_submissions_modal.no_answer_provided')}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Stack>

        <Stack flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
          <Pagination
            page={pageOptions.page || 1}
            count={Math.ceil((requestSubmissions?.count || 1) / pageOptions.per_page)}
            onChange={(_e, value) => onChangePage(value)}
          />
        </Stack>

      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>

    </Dialog >
  );
}
