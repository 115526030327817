import { Contact } from 'src/services/contacts/contacts.types';
import { Setting } from 'src/services/settings/settings.types';
import { OrgCandidate } from 'src/services/candidates/candidates.types';
import {
  User,
  Invite,
  Department,
  GetUsersProps,
  InviteUserDTO,
  CompanyDomain,
  RemoveInviteDTO,
  GetDomainsQuery,
  AddContactProps,
  MigrateAccountDto,
  IOrgBrandSettings,
  GetDepartmentQuery,
  RemoveContactProps,
  UpdateSettingProps,
  GetDepartmentsQuery,
  InviteUsersResponse,
  RemoveAccountsProps,
  UpdateUserRoleProps,
  GetOrgContactsQuery,
  SetupOrganisationDTO,
  DateSettingsResponse,
  ReferenceQuestionDto,
  GetOrgCandidatesQuery,
  GetPendingInvitesProps,
  ActivateSubTenantProps,
  UpdateBrandSettingProps,
  AddDepartmetAccountProps,
  UpdateOrgDateSettingProps,
  EditDepartmentRequestProps,
  CreateDepartmentRequestProps,
  DeleteDepartmentRequestProps,
  EditCompanyProfileRequestProps,
  JobDescriptionGenerationResponse,
  ProfileSummaryGenerationResponse,
} from 'src/services/organisation/organisation.types';

import { rootApi } from '../rootApi';
import { AccountStatus } from '../account/account.types';
import { CompanyProfile } from '../connections/connections.types';
import { APIResponse, PageOptions, HTTPRequestType, PaginatedResponse } from '../api.types';

export const orgBasePath = 'organizations';

export const organisationApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    setupOrganisation: builder.mutation<APIResponse, SetupOrganisationDTO>({
      query: (data: SetupOrganisationDTO) => ({
        url: '/organizations/setup-profile',
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['AuthContext', 'CompanyProfile'],
    }),

    // Company profile ------------------------------------------------------------
    getCompanyProfile: builder.query<CompanyProfile, string>({
      query: (org_id) => ({
        url: `/organizations/${org_id}/company-profile`,
      }),
      providesTags: ['CompanyProfile'],
    }),

    getPublicCompanyProfile: builder.query<CompanyProfile, string>({
      query: (org_id) => ({
        url: `/organizations/${org_id}/public-profile`,
      })
    }),

    updateCompanyProfile: builder.mutation<APIResponse, EditCompanyProfileRequestProps>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/company-profile`,
        method: HTTPRequestType.PUT,
        data: data.company_profile,
      }),
      invalidatesTags: ['CompanyProfile'],
    }),

    // Departments ------------------------------------------------------------

    getDepartment: builder.query<Department, GetDepartmentQuery>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/departments/${data.department_id}`,
      }),
      providesTags: (data) => [{ type: 'Department' as const, id: data?.id }],
    }),

    getDepartments: builder.query<PaginatedResponse<Department>, GetDepartmentsQuery>({
      query: (data: GetDepartmentsQuery) => ({
        url: `/organizations/${data.org_id}/departments?page=${data.page.page}&per_page=${data.page.per_page
          }${data.search_query ? `&search_query=${data.search_query}` : ''}`,
      }),
      providesTags: (data) =>
        data
          ? [...data.results.map(({ id }) => ({ type: 'Department' as const, id })), 'Department']
          : ['Department'],
    }),

    createDepartment: builder.mutation<Department, CreateDepartmentRequestProps>({
      query: (data: CreateDepartmentRequestProps) => ({
        url: `/organizations/${data.org_id}/departments`,
        method: HTTPRequestType.POST,
        data: data.department,
      }),
      invalidatesTags: ['Department'],
    }),

    editDepartment: builder.mutation<Department, EditDepartmentRequestProps>({
      query: (data: EditDepartmentRequestProps) => ({
        url: `/organizations/${data.org_id}/departments/${data.department_id}`,
        method: HTTPRequestType.PUT,
        data: data.department,
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: 'Department', id: arg.department_id }],
    }),

    deleteDepartment: builder.mutation<APIResponse, DeleteDepartmentRequestProps>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/departments/${data.department_id}`,
        method: HTTPRequestType.DELETE,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['Department']),
    }),

    // Domains ------------------------------------------------------------------

    getCompanyDomains: builder.query<PaginatedResponse<CompanyDomain>, GetDomainsQuery>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/domains`,
        params: {
          ...data.page,
          search_query: data.search_query,
        },
      }),
      providesTags: ['Domains'],
    }),

    // Users ------------------------------------------------------------------

    getAccounts: builder.query<PaginatedResponse<User>, GetUsersProps>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/accounts?page=${data.page.page}&per_page=${data.page.per_page
          }${data.search_query ? `&search_query=${data.search_query}` : ''}`,
      }),
      providesTags: (data) =>
        data
          ? [...data.results.map(({ id }) => ({ type: 'User' as const, id })), 'User']
          : ['User'],
    }),

    updateAccountStatus: builder.mutation<
      APIResponse,
      { org_id: string; account_id: string; status: AccountStatus }
    >({
      query: (data) => ({
        url: `/organizations/${data.org_id}/accounts/${data.account_id}/status`,
        method: HTTPRequestType.POST,
        data: { status: data.status },
      }),
      invalidatesTags: ['User'],
    }),
    removeAccount: builder.mutation<
      APIResponse,
      { org_id: string; account_id: string; fallback_account_id?: string }
    >({
      query: (data) => ({
        url: `/organizations/${data.org_id}/accounts/${data.account_id}/remove`,
        method: HTTPRequestType.POST,
        data: {
          fallback_account_id: data.fallback_account_id,
        },
      }),
      invalidatesTags: ['User'],
    }),

    getOrgInvites: builder.query<PaginatedResponse<Invite>, GetPendingInvitesProps>({
      query: (data: GetPendingInvitesProps) => ({
        url: `/organizations/${data.org_id}/invites`,
        params: {
          ...data.page,
          search_query: data.search_query,
        },
      }),
      providesTags: (data) => ['OrgInvites'],
    }),

    inviteAccounts: builder.mutation<InviteUsersResponse[], InviteUserDTO>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/invites`,
        method: HTTPRequestType.POST,
        data: { invites: data.invites },
      }),
      invalidatesTags: ['OrgInvites', 'Subscription'],
    }),

    getOrgInvite: builder.query<Invite, string>({
      query: (token: string) => ({
        url: `/organizations/invites/${token}`,
      }),
    }),

    removeInvite: builder.mutation<APIResponse, RemoveInviteDTO>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/invites/${data.invite_id}`,
        method: HTTPRequestType.DELETE,
      }),
      invalidatesTags: ['OrgInvites', 'Subscription'],
    }),

    // Department Users -------------------------------------------------------

    updateOrgAccountRole: builder.mutation<APIResponse, UpdateUserRoleProps>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/accounts/${data.account_id}`,
        method: HTTPRequestType.PUT,
        data: {
          org_role: data.org_role,
        },
      }),
      invalidatesTags: ['User', 'Subscription'],
    }),

    getDepartmentUsers: builder.query<
      PaginatedResponse<User>,
      GetDepartmentQuery & { page: PageOptions }
    >({
      query: (data) => ({
        url: `/organizations/${data.org_id}/departments/${data.department_id}/accounts?page=${data.page.page}&per_page=${data.page.per_page}`,
      }),
      providesTags: () => ['DepartmentUsers'],
    }),

    updateDepartmentAccount: builder.mutation<
      APIResponse,
      GetDepartmentQuery & { account_id: string; department_role: string }
    >({
      query: (data) => ({
        url: `/organizations/${data.org_id}/departments/${data.department_id}/accounts/${data.account_id}`,
        method: HTTPRequestType.PUT,
        data: {
          department_role: data.department_role,
        },
      }),
    }),

    removeDepartmentAccount: builder.mutation<APIResponse, RemoveAccountsProps>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/departments/${data.department_id}/accounts/remove`,
        method: HTTPRequestType.POST,
        data: {
          accounts: data.accounts,
        },
      }),
    }),

    addDepartmentAccount: builder.mutation<APIResponse, AddDepartmetAccountProps>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/departments/${data.department_id}/accounts/assign`,
        method: HTTPRequestType.POST,
        data: {
          accounts: data.accounts,
        },
      }),
    }),

    getOrganizationContacts: builder.query<
      PaginatedResponse<Contact>,
      GetOrgContactsQuery & { page: PageOptions }
    >({
      query: (data) => ({
        url: `/organizations/${data.org_id}/contacts`,
        params: {
          search_query: data.search_query,
          contact_type: data.contact_type,
          ...data.page,
        },
      }),
      providesTags: () => ['OrgContacts'],
    }),

    addContact: builder.mutation<APIResponse, AddContactProps>({
      query: (data) => ({
        url: `/organizations/${data.org_id}/contacts`,
        method: HTTPRequestType.POST,
        data: {
          account: data.account,
          manual_contact: data.manual_contact,
          source: data.source,
        },
      }),
      invalidatesTags: ['OrgContacts'],
    }),

    removeContact: builder.mutation<APIResponse, RemoveContactProps>({
      query: (data) => ({
        url: `/organizations/contacts/${data.contact_id}`,
        method: HTTPRequestType.DELETE,
      }),
      invalidatesTags: ['OrgContacts'],
    }),

    // Org Candidates
    getOrganizationCandidates: builder.query<
      PaginatedResponse<OrgCandidate>,
      GetOrgCandidatesQuery & { page: PageOptions }
    >({
      query: (data) => ({
        url: `/my/candidates`,
        params: {
          search_query: data.search_query,
          skills_query: data.skills_query,
          availability_status: data.availability_status,
          ...data.page,
        },
      }),
      providesTags: () => ['OrgCandidates'],
    }),

    getOrganizationCandidate: builder.query<OrgCandidate, string>({
      query: (id: string) => ({
        url: `/my/candidates/${id}`,
        method: HTTPRequestType.GET,
      }),
      providesTags: ['OrgCandidates'],
    }),

    // Org settings
    getOrganizationSettings: builder.query<Setting[], string>({
      query: (organizationId: string) => ({
        url: `/organizations/${organizationId}/settings`,
      }),
      providesTags: ['OrgSettings'],
    }),

    updateOrganizationSettings: builder.mutation<APIResponse, UpdateSettingProps>({
      query: (data) => ({
        url: `/organizations/${data.organizationId}/settings`,
        method: HTTPRequestType.POST,
        data: {
          settings: data.settings,
        },
      }),
      invalidatesTags: ['OrgSettings'],
    }),

    getOrganizationBrandSettings: builder.query<IOrgBrandSettings, string>({
      query: (organizationId: string) => ({
        url: `/organizations/${organizationId}/brand-settings`,
      }),
      providesTags: ['OrgBrandSettings'],
    }),

    updateOrganizationBrandSettings: builder.mutation<APIResponse, UpdateBrandSettingProps>({
      query: (data) => ({
        url: `/organizations/${data.organizationId}/brand-settings`,
        method: HTTPRequestType.PUT,
        data: {
          primary_color: data.primary_color,
          secondary_color: data.secondary_color,
          brand_logo_id: data.brand_logo_id,
          exporter_header_image_id: data.exporter_header_image_id,
          exporter_footer_image_id: data.exporter_footer_image_id,
          exporter_cover_field_mapping: data.exporter_cover_field_mapping,
          exporter_font: data.exporter_font,
        },
      }),
      invalidatesTags: ['OrgBrandSettings'],
    }),

    // date settings
    getOrganizationDateSettings: builder.query<DateSettingsResponse, string>({
      query: (organizationId: string) => ({
        url: `/organizations/${organizationId}/date-settings`,
      }),
      providesTags: ['OrgDateSettings'],
    }),

    updateOrganizationDateSettings: builder.mutation<APIResponse, UpdateOrgDateSettingProps>({
      query: (data) => ({
        url: `/organizations/${data.organizationId}/date-settings`,
        method: HTTPRequestType.PUT,
        data: {
          weekday_settings: data.weekday_settings,
          custom_date_settings: data.custom_date_settings,
        },
      }),
      invalidatesTags: ['OrgDateSettings'],
    }),

    //  Reference settings
    getReferenceTemplateSettings: builder.query<{ question: string, required: boolean }[], string>({
      query: (organizationId: string) => ({
        url: `/organizations/${organizationId}/reference-template-questions`,
      }),
    }),

    updateReferenceTemplateSettings: builder.mutation<APIResponse, { organizationId: string; questions: ReferenceQuestionDto[] }>({
      query: (data) => ({
        url: `/organizations/${data.organizationId}/reference-template-questions`,
        method: HTTPRequestType.POST,
        data: {
          questions: data.questions,
        },
      }),
    }),

    // Activities

    getOrganizationActivities: builder.query<
      PaginatedResponse<IActivity>,
      { organizationId: string; page: number; perPage: number }
    >({
      query: ({ organizationId, perPage, page }) => ({
        url: `/organizations/${organizationId}/activities`,
        params: {
          page,
          per_page: perPage,
        },
      }),
    }),

    // Sub tenant endpoints
    getMigratableAccounts: builder.query<User[], void>({
      query: () => ({
        url: `/organizations/sub-tenant/accounts/migratable`,
      }),
      providesTags: ['ParentAccounts'],
    }),
    migrateAccounts: builder.mutation<APIResponse, MigrateAccountDto>({
      query: (data) => ({
        url: `/organizations/sub-tenant/accounts/migrate`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['User', 'Subscription', 'ParentAccounts'],
    }),
    activateSubTenant: builder.mutation<APIResponse, ActivateSubTenantProps>({
      query: (data) => ({
        url: `/organizations/sub-tenant/activate`,
        method: HTTPRequestType.POST,
        data,
      }),
      invalidatesTags: ['AuthContext', 'Accounts'],
    }),

    // ai-features
    improveJobDescription: builder.mutation<JobDescriptionGenerationResponse, { job_id: string }>({
      query: (data) => ({
        url: `/organizations/features/generate-job-description`,
        method: HTTPRequestType.POST,
        data
      }),
    }),
    improveApplicationProfile: builder.mutation<ProfileSummaryGenerationResponse, { candidate_profile_id: string }>({
      query: (data) => ({
        url: `/organizations/features/generate-profile-summary`,
        method: HTTPRequestType.POST,
        data
      }),
    }),

  }),
});

export const {
  useSetupOrganisationMutation,

  useGetDepartmentsQuery,
  useLazyGetDepartmentsQuery,
  useCreateDepartmentMutation,
  useEditDepartmentMutation,
  useDeleteDepartmentMutation,

  useGetDepartmentQuery,
  useGetDepartmentUsersQuery,
  useLazyGetDepartmentUsersQuery,
  useUpdateDepartmentAccountMutation,
  useRemoveDepartmentAccountMutation,
  useAddDepartmentAccountMutation,

  useGetAccountsQuery,
  useLazyGetAccountsQuery,
  useUpdateOrgAccountRoleMutation,
  useUpdateAccountStatusMutation,
  useRemoveAccountMutation,

  useLazyGetOrgInvitesQuery,
  useInviteAccountsMutation,
  useRemoveInviteMutation,
  useLazyGetOrgInviteQuery,

  useGetCompanyProfileQuery,
  useGetPublicCompanyProfileQuery,
  useUpdateCompanyProfileMutation,

  useLazyGetCompanyDomainsQuery,
  useGetCompanyDomainsQuery,

  useGetOrganizationContactsQuery,
  useAddContactMutation,
  useRemoveContactMutation,

  useGetOrganizationCandidatesQuery,
  useGetOrganizationCandidateQuery,

  useGetOrganizationSettingsQuery,
  useUpdateOrganizationSettingsMutation,
  useGetOrganizationBrandSettingsQuery,
  useUpdateOrganizationBrandSettingsMutation,

  useGetOrganizationDateSettingsQuery,
  useUpdateOrganizationDateSettingsMutation,

  useGetOrganizationActivitiesQuery,

  useGetReferenceTemplateSettingsQuery,
  useUpdateReferenceTemplateSettingsMutation,

  useGetMigratableAccountsQuery,
  useMigrateAccountsMutation,
  useActivateSubTenantMutation,

  useImproveJobDescriptionMutation,
  useImproveApplicationProfileMutation,
} = organisationApi;
