
import * as Yup from 'yup';
import { useMemo, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Grid,
  Alert,
  Stack,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useCreateReferenceRequestMutation } from 'src/services/references/references.service';
import { useGetOrganizationSettingsQuery, useGetReferenceTemplateSettingsQuery } from 'src/services/organisation/organisation.service';

import Iconify from 'src/components/iconify';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import RHFNumberInput from 'src/components/hook-form/rhf-number-input';

type Props = {
  open: boolean;
  applicationId: string;
  onClose: VoidFunction;
};

interface FormValues {
  referee_count: number;
  questions: Array<{ question: string; required: boolean }>;
  notes?: string | null;
}

const MAX_QUESTIONS = 10;
const MAX_REFEREES = 10;

export default function RequestReferenceModal({ open, applicationId, onClose }: Props) {

  const { t } = useTranslate();

  const tenant = useOrgTenant();

  const authContext = useAuthContext();

  const dispatch = useAppDispatch();

  const { currentData: settingsData, isLoading: isLoadingSettings } = useGetOrganizationSettingsQuery(
    authContext.organization.id
  );

  const { currentData: templateQuestions, isLoading: isLoadingTemplateQuestions } = useGetReferenceTemplateSettingsQuery(authContext.organization.id);

  const [createRequest, { isLoading: isSubmitting }] = useCreateReferenceRequestMutation();

  const CreateRequestSchema = Yup.object().shape({
    referee_count: Yup.number().min(1, t('validation.min_integer', { min: 1 })).max(MAX_REFEREES, t('validation.max_integer', { max: MAX_REFEREES })).required(t('validation.required')),
    questions: Yup.array().of(Yup.object().shape({
      question: Yup.string().required(t('validation.required')),
      required: Yup.boolean().required(t('validation.required')),
    })).min(1, t('validation.min_array', { min: 1 })).max(MAX_QUESTIONS, t('validation.max_array', { max: MAX_QUESTIONS })).required(t('validation.required')),
    notes: Yup.string().nullable(),
  });

  const defaultValues = {
    referee_count: 1,
    questions: (Array.isArray(templateQuestions) && templateQuestions.length > 0) ? templateQuestions : [],
    notes: null,
  };

  const methods = useForm({
    resolver: yupResolver(CreateRequestSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { isValid, errors },
  } = methods;

  useEffect(() => {
    if (Array.isArray(templateQuestions) && templateQuestions.length > 0) {
      reset({
        questions: templateQuestions
      });
    }
  }, [templateQuestions, reset]);

  const areQuestionsEditable = useMemo(() => {
    if (!settingsData) return false;

    const referenceQuestionSetting = settingsData.find(
      (setting) => setting.setting_type === 'reference_template_questions_editable'
    );

    return referenceQuestionSetting ? referenceQuestionSetting.setting_value : false;
  }, [settingsData]);

  const questions = watch('questions');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  const createRequestHandler = async (data: FormValues) => {
    try {

      const response = await createRequest({
        applicationId,
        data: {
          referee_count: data.referee_count,
          questions: data.questions,
          notes: data.notes ?? '',
        }
      }).unwrap();

      enqueueSnackbar(t('applications.references.initiate_request_modal.api.success'), { variant: 'success' });
      reset();
      onClose();
    }
    catch (error) {
      enqueueSnackbar(t('applications.references.initiate_request_modal.api.default_error'), { variant: 'error' });
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" >
      <DialogTitle>{t('applications.references.initiate_request_modal.title')}</DialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <Stack direction="column" spacing={2}>

            <RHFNumberInput
              name="referee_count"
              label={t('applications.references.initiate_request_modal.labels.referee_count')}
            />

            <RHFTextField
              name="notes"
              label={t('applications.references.initiate_request_modal.labels.candidate_notes')}
              multiline
              rows={4}
            />

            {
              !areQuestionsEditable && (
                <Alert severity="warning" icon={false}>
                  {t('applications.references.initiate_request_modal.editing_disabled_message')}
                </Alert>
              )
            }
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>

              {fields.map((field, index) => (
                <>
                  <Grid item xs={11} sm={8}>
                    <RHFTextField
                      disabled={!areQuestionsEditable}
                      name={`questions.${index}.question`}
                      label={t('applications.references.initiate_request_modal.labels.question')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={11} sm={3}>
                    <RHFCheckbox
                      disabled={!areQuestionsEditable}
                      name={`questions.${index}.required`}
                      label={t('applications.references.initiate_request_modal.labels.required')}
                    />
                  </Grid>

                  <Grid item xs={12} sm={1}>
                    <IconButton sx={{ mt: .5 }} onClick={() => remove(index)} color="error" disabled={!areQuestionsEditable}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </>
              ))}

              <Grid item xs={12}>
                {
                  errors.questions?.root && (
                    <Typography variant="caption" color="error">
                      {errors.questions?.root.message}
                    </Typography>
                  )
                }
              </Grid>

              <Grid item xs={12} />

            </Grid>
          </Stack>

        </FormProvider>
      </DialogContent>

      <DialogActions>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" width="100%">
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>

            {
              areQuestionsEditable && (
                <Button startIcon={<Iconify icon="gridicons:add-outline" />} disabled={questions.length > MAX_QUESTIONS} variant="contained" color="primary" onClick={() => append({ question: '', required: true })}>
                  {t('admin-organization.settings.references.labels.add_question')}
                </Button>
              )
            }

          </Box>

          <Stack direction="row" spacing={2}>
            <Button onClick={onClose}>{t('common.close')}</Button>
            <LoadingButton
              type="submit"
              onClick={handleSubmit(createRequestHandler)}
              variant="contained"
              loading={isSubmitting}
              disabled={!isValid}
            >
              {t('applications.references.initiate_request_modal.labels.send_request')}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogActions>

    </Dialog >
  );
}
