import moment from 'moment';
import { Fragment, useState, useCallback } from 'react';

import {
  List,
  Stack,
  Button,
  Dialog,
  Divider,
  ListItem,
  IconButton,
  Pagination,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useGetRefereeRequestsQuery } from 'src/services/candidates/candidates.service';
import { ReferenceRequestStatus, ReferenceRequestResponse } from 'src/services/references/references.types';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import EmptyContent from 'src/components/empty-content';

import RefereeRequestSubmissionsModal from 'src/sections/profile/references/modal/referee-request-submissions-modal';

type Props = {
  open: boolean;
  profileId: string;
  refereeId: string;
  onClose: VoidFunction;
};

export default function RefereeRequestListModal({ open, profileId, refereeId, onClose }: Props) {
  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const dispatch = useAppDispatch();

  const viewSubmissionsModalControl = useBoolean();

  const [selectedRequest, setSelectedRequest] = useState<string | null>(null);

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 1,
    per_page: 5,
  });

  const { currentData: pendingReferenceRequests } = useGetRefereeRequestsQuery({
    profileId,
    refereeId,
    pageOptions
  });

  const onChangePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  const getStatusLabelText = (request: ReferenceRequestResponse) => {
    switch (request.status) {
      case ReferenceRequestStatus.PENDING_APPROVAL:
        return t('applications.references.request_list_modal.status.pending_approval');
      case ReferenceRequestStatus.PENDING_REFEREES:
        return t('applications.references.request_list_modal.status.pending_referees');
      case ReferenceRequestStatus.SENT:
        return t('applications.references.request_list_modal.status.sent');
      case ReferenceRequestStatus.COMPLETED:
        return t('applications.references.request_list_modal.status.completed');
      case ReferenceRequestStatus.EXPIRED:
        return t('applications.references.request_list_modal.status.expired');
      case ReferenceRequestStatus.REJECTED:
        return t('applications.references.request_list_modal.status.rejected');
      default:
        return '';
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('profile.referees.past_referee_requests_modal.title')}</DialogTitle>
      <DialogContent>

        <Stack
          direction="column"
          sx={{ minHeight: 400, height: '100%', typography: 'body1' }}
          justifyContent="space-between"
        >
          {pendingReferenceRequests?.count === 0 ? (
            <EmptyContent
              filled
              title={t('profile.referees.past_referee_requests_modal.no_requests')}
              sx={{
                py: 10,
              }}
            />
          ) : (
            <List sx={{ paddingBottom: 2, maxWidth: '100%' }}>
              {pendingReferenceRequests?.results.map((referenceRequest) => (
                <Fragment key={referenceRequest.id}>
                  <ListItem
                    sx={{ paddingY: 1 }}
                    secondaryAction={
                      <Stack direction="row" alignItems="center">
                        <Label color="info">{getStatusLabelText(referenceRequest)}</Label>
                        <IconButton
                          onClick={() => {
                            setSelectedRequest(referenceRequest.id);
                            viewSubmissionsModalControl.onTrue();
                          }}
                        >
                          <Iconify icon="gg:arrow-right-o" />
                        </IconButton>
                      </Stack>
                    }
                  >
                    <ListItemText
                      primary={t('profile.referees.past_referee_requests_modal.submitted_at', { date: moment(referenceRequest.created_at).format('DD/MM/YYYY hh:mm A') })}
                      secondary={t('profile.referees.past_referee_requests_modal.job', { title: referenceRequest.job?.title ?? "N/A" })}
                    />
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
          )}

          <Stack flexDirection="row" justifyContent="center" sx={{ py: 2 }}>
            <Pagination
              page={pageOptions.page || 1}
              count={Math.ceil((pendingReferenceRequests?.count || 1) / pageOptions.per_page)}
              onChange={(_e, value) => onChangePage(value)}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>


      {
        (viewSubmissionsModalControl.value && selectedRequest) && (
          <RefereeRequestSubmissionsModal
            open={viewSubmissionsModalControl.value}
            profileId={profileId}
            refereeId={refereeId}
            requestId={selectedRequest}
            onClose={viewSubmissionsModalControl.onFalse}
          />
        )
      }

    </Dialog>
  );
}
