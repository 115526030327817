import { useState } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Card,
  Stack,
  Skeleton,
  Typography,
  CardContent
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { EmptySubmissionSVG } from 'src/theme/illustrations';
import { useGetReferencesByApplicationQuery } from 'src/services/references/references.service';

import ApplicationReferencesTable from 'src/sections/jobs/applications/detail/application-references-table/application-references-table';

type Props = {
  applicationId: string;
};

const DEFAULT_PAGE = {
  page: 1,
  per_page: 10,
};

export default function ApplicationReferencesView({ applicationId }: Props) {

  const { t } = useTranslate();

  const [page, setPage] = useState(DEFAULT_PAGE);

  const { data: referenceSubmissions, isLoading, error } = useGetReferencesByApplicationQuery(
    {
      applicationId,
      params: {
        ...page
      }
    },
    {
      skip: !applicationId
    }
  );

  // loading state
  if (isLoading) {
    return (
      <Card>
        <CardContent>
          <Skeleton variant="text" width={200} height={32} sx={{ mb: 3 }} />
          {[1, 2, 3].map((i) => (
            <Box key={i} sx={{ mb: 2 }}>
              <Skeleton variant="text" width={150} />
              <Skeleton variant="rectangular" height={80} sx={{ mt: 1 }} />
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  }

  // empty state
  if (!referenceSubmissions || referenceSubmissions.count === 0) {
    return (
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" py={4}>
            <EmptySubmissionSVG style={{ width: '180px', height: '180px' }} />
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <HelpOutlineIcon fontSize="small" />
                {t('applications.detail.references.no_submissions_title')}
              </Typography>
              <Typography variant="body2" color="text.secondary" mt={1}>
                {t('applications.detail.references.no_submissions_desc')}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }

  // data list display
  return (
    <Stack spacing={2}>
      <ApplicationReferencesTable applicationId={applicationId} />
    </Stack>
  );
}