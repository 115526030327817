import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { authApi } from 'src/services/auth/auth.service';
import { AuthContext } from 'src/services/auth/auth.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { Business_Tenants } from 'src/services/auth/auth.utils';
import { useOrgHierarchy } from 'src/auth/hooks/useOrgHierarchy';
import { UserRole } from 'src/services/organisation/organisation.enum';

import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify/iconify';

import { TenantType, TenantHierarchyLevel } from 'src/types/enums';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  contacts: icon('ic_contacts'),
  city: icon('ic_city'),
  trophy: icon('ic_trophy'),
  candidate: icon('ic_candidate'),
  checklist: icon('ic_checklist'),
  integrations: <Iconify icon="solar:bolt-circle-bold" width={24} />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const authResult = authApi.endpoints.getAuthContext.useQueryState();
  const { currentData: authData } = authResult;
  const { account, user, organization } = authData as AuthContext;

  const tenant = useOrgTenant();
  const hierarchyLevel = useOrgHierarchy();

  const isAdmin = [UserRole.Admin, UserRole.Op_Manager].includes(account.org_role);

  const isBusinessTenant = Business_Tenants.includes(tenant);

  const { t } = useTranslate();

  const data = useMemo(
    () => [
      {
        subheader: `${t(`nav.tenant_label.${tenant}_${hierarchyLevel}`)} v${import.meta.env.VITE_APP_VERSION ?? import.meta.env.VITE_REACT_APP_VERSION
          }`,
        items: [
          {
            title: t(`navigation.titles.activity`),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
            id: 'activity-nav-menu-item',
          },
          {
            title: t('navigation.titles.jobs'),
            path: paths.dashboard.jobs.root,
            icon: ICONS.job,
            id: 'jobs-nav-menu-item',
          },
          ...(tenant === TenantType.Candidate || tenant === TenantType.Recruiter
            ? [
              {
                title: t(`navigation.titles.connections.clients`),
                path: paths.dashboard.connections.clients,
                icon: ICONS.city,
                id: 'clients-nav-menu-item',
              },
            ]
            : []),
          ...(tenant === TenantType.Candidate || tenant === TenantType.Client
            ? [
              {
                title: t(`navigation.titles.connections.recruiters`),
                path: paths.dashboard.connections.recruiters,
                icon: ICONS.trophy,
                id: 'recruiters-nav-menu-item',
              },
            ]
            : []),
          ...(isBusinessTenant
            ? []
            : [
              {
                title: t(`navigation.titles.profile`),
                path: paths.dashboard.profile.root,
                icon: ICONS.user,
                id: 'profile-nav-menu-item',
              },
              {
                title: t(`navigation.titles.documents`),
                path: paths.dashboard.profile.documents,
                icon: ICONS.candidate,
                id: 'cp-documents-nav-menu-item',
              },
              {
                title: t(`navigation.titles.referees`),
                path: paths.dashboard.profile.referees.root,
                icon: ICONS.checklist,
                id: 'cp-references-nav-menu-item',
              },
            ]),
          ...(isBusinessTenant
            ? [
              {
                title: t(`navigation.titles.candidates`),
                path: paths.dashboard.candidates.root,
                icon: ICONS.candidate,
                id: 'candidates-nav-menu-item',
              },
            ]
            : []),
          {
            title: t('navigation.titles.contacts'),
            path: paths.dashboard.contacts.root,
            icon: ICONS.contacts,
            id: 'contacts-nav-menu-item',
          },
        ],
      },

      // ADMIN
      // ----------------------------------------------------------------------
      ...(isAdmin && isBusinessTenant
        ? [
          {
            subheader: t('navigation.titles.admin.subheader'),
            items: [
              {
                title: t('admin-organization.title'),
                path: paths.dashboard.admin.organization.root,
                icon: ICONS.user,
                id: 'organisation-nav-menu-item',
              },
              ...(hierarchyLevel === TenantHierarchyLevel.PARENT
                ? [
                  {
                    title: t('billing.title'),
                    path: paths.dashboard.admin.billing.root,
                    icon: ICONS.banking,
                    id: 'billing-nav-menu-item',
                  },
                ]
                : []),
              {
                title: t('navigation.titles.admin.connections'),
                path: paths.dashboard.admin.connection_management.root,
                icon: ICONS.blog,
              },
              // {
              //   title: t('navigation.titles.admin.documents'),
              //   path: paths.dashboard.admin.documents.root,
              //   icon: ICONS.folder,
              // },
              ...(hierarchyLevel === TenantHierarchyLevel.PARENT
                ? [
                  {
                    title: t('navigation.titles.admin.integrations'),
                    path: paths.dashboard.admin.integrations.root,
                    icon: ICONS.integrations,
                  },
                ]
                : []),
            ],
          },
        ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdmin, isBusinessTenant, tenant, hierarchyLevel]
  );

  return data;
}
