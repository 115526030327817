
import { useMemo, useState } from 'react';

import {
    Card,
    Badge,
    Stack,
    Button,
    Divider,
    CardHeader,
    Typography,
    CardContent
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { OfferStatus } from 'src/services/applications/applications.types';
import { useGetApplicationInterviewsQuery } from 'src/services/interviews/interviews.service';
import { useGetReferenceRequestsByApplicationQuery } from 'src/services/references/references.service';
import { useGetApplicationByIdQuery, useGetApplicationOffersQuery, useGetIncomingApplicationResourceRequestsQuery, useGetOutgoingApplicationResourceRequestsQuery } from 'src/services/applications/applications.service';

import Iconify from 'src/components/iconify';

import { TenantType } from 'src/types/enums';

import OffersListModal from '../modals/offer/offers-list-modal';
import InterviewListModal from '../modals/interview/interview-list-modal';
import ReferenceRequestListModal from '../modals/references/reference-request-list-modal';
import ResourceRequestsListModal from '../modals/resource-requests/resource-requests-list-modal';

type Props = {
    applicationId: string;
};

// Surface out important pending actions for a given applicaiton
// Some examples include pending interviews, pending offers, resource requests, reference requests, etc.

export default function PendingActionsCard({ applicationId }: Props) {
    const { t } = useTranslate();

    const tenantType = useOrgTenant();

    const dispatch = useAppDispatch();

    const interviewListModalControl = useBoolean();

    const referenceRequestsModalControl = useBoolean();

    const resourceRequestsListModalControl = useBoolean();

    const offerListModalControl = useBoolean();

    const [resourceRequestsPageOptions, setResourceRequestsPageOptions] = useState<PageOptions>({
        page: 1,
        per_page: 5,
    });

    const { currentData: application } = useGetApplicationByIdQuery(
        applicationId as string,
        {
            skip: !applicationId,
        }
    );

    const { currentData: incomingResourceRequests } = useGetIncomingApplicationResourceRequestsQuery(
        {
            applicationId: applicationId as string,
            params: resourceRequestsPageOptions
        },
        { skip: !applicationId || tenantType !== TenantType.Candidate }
    );

    const { currentData: outgoingResourceRequests } = useGetOutgoingApplicationResourceRequestsQuery(
        {
            applicationId: applicationId as string,
            params: resourceRequestsPageOptions
        },
        { skip: !applicationId || tenantType === TenantType.Candidate }
    );

    const { currentData: referenceRequests } = useGetReferenceRequestsByApplicationQuery({
        applicationId,
        status: 'pending',
        params: {
            page: 1,
            per_page: 1,
        }
    }, {
        skip: !applicationId
    });

    const { currentData: interviews } = useGetApplicationInterviewsQuery({
        applicationId,
        params: {
            page: 1,
            per_page: 1,
        }
    }, {
        skip: !applicationId
    });

    const { currentData: offers, isLoading: isLoadingOffers } = useGetApplicationOffersQuery(
        {
            applicationId: applicationId as string,
            params: { page: 1, per_page: 100 },
        },
        { skip: !applicationId }
    );

    const pendingOffers = useMemo(() => {
        if (!offers) return [];

        return offers.results.filter((offer) => [OfferStatus.SENT, OfferStatus.CHANGES_REQUESTED, OfferStatus.IN_REVIEW].includes(offer.status));
    }, [offers]);

    const renderContent = () => {

        if (!application) {
            return null;
        }

        return (
            <Stack spacing={1}>

                {
                    (!incomingResourceRequests?.count && !outgoingResourceRequests?.count && !referenceRequests?.count && !interviews?.count && !pendingOffers?.length) && (
                        <Stack
                            flexGrow={1}
                            textAlign="center"
                            justifyContent="center"
                            alignItems="center"
                            padding={2}
                            gap={2}
                        >
                            <Typography variant="subtitle2">
                                {t('applications.pending_actions_card.no_actions')}
                            </Typography>
                        </Stack>
                    )
                }

                {
                    !!interviews?.count && (
                        <Stack
                            direction="row"
                            spacing={1}
                            p={2}
                            justifyContent="space-between"
                            alignItems="center"
                            boxShadow={1}
                            borderRadius={1}
                        >
                            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                <Iconify icon="fluent:slide-text-call-48-regular" />
                                <Typography variant='body2'>{t('applications.pending_actions_card.labels.interviews')}</Typography>
                                <Badge badgeContent={interviews?.count} color="primary" sx={{ ml: 1 }} />
                            </Stack>

                            <Button
                                variant="text"
                                color="primary"
                                onClick={interviewListModalControl.onTrue}
                            >
                                {t('common.view')}
                            </Button>
                        </Stack>
                    )
                }


                {

                    !!pendingOffers?.length && (
                        <Stack
                            direction="row"
                            spacing={1}
                            p={2}
                            justifyContent="space-between"
                            alignItems="center"
                            boxShadow={1}
                            borderRadius={1}
                        >
                            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                <Iconify icon="material-symbols-light:contract" />
                                <Typography variant='body2'>{t('applications.pending_actions_card.labels.offers')}</Typography>
                                <Badge badgeContent={pendingOffers?.length} color="primary" sx={{ ml: 1 }} />
                            </Stack>

                            <Button
                                variant="text"
                                color="primary"
                                onClick={offerListModalControl.onTrue}
                            >
                                {t('common.view')}
                            </Button>
                        </Stack>

                    )
                }

                {
                    (!!incomingResourceRequests?.count || !!outgoingResourceRequests?.count) &&
                    (
                        <Stack
                            direction="row"
                            spacing={1}
                            padding={2}
                            justifyContent="space-between"
                            alignItems="center"
                            boxShadow={1}
                            borderRadius={1}
                        >
                            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                <Iconify icon="codicon:git-pull-request-go-to-changes" />
                                <Typography variant='body2'>{t('applications.pending_actions_card.labels.resource_requests')}</Typography>
                                <Badge badgeContent={tenantType === TenantType.Candidate ? incomingResourceRequests?.count : outgoingResourceRequests?.count} color="primary" sx={{ ml: 1 }} />
                            </Stack>

                            <Button
                                variant="text"
                                color="primary"
                                onClick={resourceRequestsListModalControl.onTrue}
                            >
                                {t('common.view')}
                            </Button>
                        </Stack>
                    )
                }

                {
                    !!referenceRequests?.count && (
                        <Stack
                            direction="row"
                            spacing={1}
                            p={2}
                            justifyContent="space-between"
                            alignItems="center"
                            boxShadow={1}
                            borderRadius={1}
                        >
                            <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                <Iconify icon="solar:diploma-verified-outline" />
                                <Typography variant='body2'>{t('applications.pending_actions_card.labels.reference_requests')}</Typography>
                                <Badge badgeContent={referenceRequests?.count} color="primary" sx={{ ml: 1 }} />
                            </Stack>

                            <Button
                                variant="text"
                                color="primary"
                                onClick={referenceRequestsModalControl.onTrue}
                            >
                                {t('common.view')}
                            </Button>
                        </Stack>
                    )
                }

            </Stack>
        );
    }

    return (
        <Card>
            <CardHeader
                title={t('applications.pending_actions_card.title')}
                avatar={<Iconify icon="ic:outline-pending-actions" />}
            />

            <CardContent sx={{ pt: 1 }}>

                <Divider />

                <Stack spacing={1}>
                    {renderContent()}
                </Stack>

                {
                    application && (
                        <InterviewListModal
                            applicationId={applicationId as string}
                            open={interviewListModalControl.value}
                            onClose={interviewListModalControl.onFalse}
                        />
                    )
                }

                {
                    application && (
                        <ReferenceRequestListModal
                            applicationId={applicationId as string}
                            open={referenceRequestsModalControl.value}
                            onClose={referenceRequestsModalControl.onFalse}
                        />
                    )
                }

                {
                    application && (
                        <ResourceRequestsListModal
                            open={resourceRequestsListModalControl.value}
                            onClose={resourceRequestsListModalControl.onFalse}
                            target="incoming"
                            requests={tenantType === TenantType.Candidate ? incomingResourceRequests : outgoingResourceRequests}
                            pageOptions={resourceRequestsPageOptions}
                            onChangePage={(_e, pageNumber) =>
                                setResourceRequestsPageOptions({ ...resourceRequestsPageOptions, page: pageNumber })
                            }
                            profileId={application.attached_profile.id as string}
                        />
                    )
                }

                {
                    application && (
                        <OffersListModal
                            applicationId={applicationId as string}
                            open={offerListModalControl.value}
                            onClose={offerListModalControl.onFalse}
                        />
                    )
                }

            </CardContent>

        </Card>
    );
}
