import * as Yup from 'yup';
import { useEffect } from 'react';
import { Trans } from "react-i18next";
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';

import { Stack } from '@mui/system';
import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Grid,
  Alert,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress
} from "@mui/material";

import { useTranslate } from "src/locales";
import { useAuthContext } from "src/auth/hooks/useAuthContext";
import { ReferenceReviewAction, ReviewReferenceRequestDTO } from 'src/services/references/references.types';
import {
  useGetReferenceRequestByIdQuery,
  useReviewReferenceRequestMutation
} from "src/services/references/references.service";

import Iconify from 'src/components/iconify';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';

type Props = {
  open: boolean;
  referenceRequestId: string;
  onClose: VoidFunction;
};

interface FormValues {
  questions: Array<{ question: string; required: boolean }>;
  notes?: string | null;
}

const MAX_QUESTIONS = 10;

export default function ReviewRequestModal({ open, referenceRequestId, onClose }: Props) {
  const { t } = useTranslate();
  const authContext = useAuthContext();

  const [reviewRequest, { isLoading: isReviewingRequest }] = useReviewReferenceRequestMutation();

  const { currentData: referenceRequest, isLoading: loadingRequest } = useGetReferenceRequestByIdQuery({
    referenceRequestId
  }, {
    skip: !referenceRequestId
  });

  const ReviewRequestSchema = Yup.object().shape({
    questions: Yup.array().of(Yup.object().shape({
      question: Yup.string().required(t('validation.required')),
      required: Yup.boolean().required(t('validation.required')),
    })).min(1, t('validation.min_array', { min: 1 })).max(MAX_QUESTIONS, t('validation.max_array', { max: MAX_QUESTIONS })).required(t('validation.required')),
    notes: Yup.string().nullable(),
  });

  const defaultValues = {
    referee_count: 1,
    questions: referenceRequest?.questions || [{ question: '', required: false }],
    notes: "",
  };

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(ReviewRequestSchema)
  });

  const {
    handleSubmit,
    reset,
    control,
    watch,
    trigger,
    getValues,
    formState: { errors },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  const questionsData = watch('questions');

  useEffect(() => {
    if (referenceRequest?.questions) {
      reset({
        questions: referenceRequest.questions
      });
    }
  }, [referenceRequest, reset]);

  const reviewReferenceRequest = async (action: ReferenceReviewAction) => {
    try {

      let data: ReviewReferenceRequestDTO = {
        action
      }

      if (action === ReferenceReviewAction.APPROVE) {

        const isValid = await trigger();
        if (!isValid) return;

        const { questions, notes } = getValues();

        data = {
          ...data,
          questions,
          notes: notes as string ?? ''
        }
      }
      else {

        const { notes } = getValues();

        data = {
          ...data,
          notes: notes as string
        }
      }

      await reviewRequest({
        referenceRequestId,
        applicationId: referenceRequest?.group.application.id as string,
        data
      }).unwrap();

      if (action === ReferenceReviewAction.APPROVE) {
        enqueueSnackbar(t('applications.references.review_request_modal.api.approve.success'), { variant: 'success' });
      }
      else {
        enqueueSnackbar(t('applications.references.review_request_modal.api.reject.success'), { variant: 'success' });
      }

      onClose();
    } catch (error) {
      console.log(error);
      if (action === ReferenceReviewAction.APPROVE) {
        enqueueSnackbar(t('applications.references.review_request_modal.api.approve.default_error'), { variant: 'error' });
      }
      else {
        enqueueSnackbar(t('applications.references.review_request_modal.api.reject.default_error'), { variant: 'error' });
      }
    }
  };

  const isLoading = loadingRequest;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {t('applications.references.review_request_modal.title')}
      </DialogTitle>
      <DialogContent >

        {isLoading && (
          <CircularProgress sx={{ display: 'block', mx: 'auto', my: 2 }} />
        )}

        {(!isLoading && referenceRequest) && (
          <Stack spacing={2}>
            <Alert severity="info">
              {t('applications.references.review_request_modal.alert')}
            </Alert>

            {
              referenceRequest.notes && (
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      <Trans>
                        {t('applications.references.review_request_modal.notes', { notes: referenceRequest.notes })}
                      </Trans>
                    </Typography>
                  </Grid>
                </Grid>
              )
            }

          </Stack>
        )}

        <FormProvider {...methods}>
          <Stack direction="column" spacing={2} mt={2}>

            <Stack direction="row" spacing={2} justifyContent="space-between" alignContent="center">
              <Typography variant="subtitle2">
                {t('applications.references.review_request_modal.questions')}
              </Typography>
              <IconButton disabled={questionsData.length > MAX_QUESTIONS} color="primary" onClick={() => append({ question: '', required: true })}>
                <Iconify icon="gridicons:add-outline" />
              </IconButton>
            </Stack>

            <Grid container spacing={2} sx={{ alignItems: 'center' }}>

              {fields.map((field, index) => (
                <>
                  <Grid item xs={11} sm={8}>
                    <RHFTextField
                      name={`questions.${index}.question`}
                      label={t('applications.references.initiate_request_modal.labels.question')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={11} sm={3}>
                    <RHFCheckbox
                      name={`questions.${index}.required`}
                      label={t('applications.references.initiate_request_modal.labels.required')}
                    />
                  </Grid>

                  <Grid item xs={12} sm={1}>
                    <IconButton sx={{ mt: .5 }} onClick={() => remove(index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </>
              ))}

              <Grid item xs={12}>
                {
                  errors.questions?.root && (
                    <Typography variant="caption" color="error">
                      {errors.questions?.root.message}
                    </Typography>
                  )
                }
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  {t('applications.references.review_request_modal.candidate_notes')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  name="notes"
                  label={t('applications.references.review_request_modal.candidate_notes_placeholder')}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>

          </Stack>
        </FormProvider>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {t('common.cancel')}
        </Button>
        <LoadingButton
          loading={isReviewingRequest}
          variant="contained"
          color="primary"
          size='small'
          onClick={() => reviewReferenceRequest(ReferenceReviewAction.APPROVE)}
        >
          {t('common.approve')}
        </LoadingButton>
        <LoadingButton
          loading={isReviewingRequest}
          variant="contained"
          color="error"
          size='small'
          onClick={() => reviewReferenceRequest(ReferenceReviewAction.REJECT)}
        >
          {t('common.reject')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}