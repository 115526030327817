import _ from 'lodash';
import moment from 'moment';
import { Fragment, useState, useCallback } from 'react';

import {
  Tab,
  List,
  Tabs,
  Alert,
  Stack,
  Button,
  Dialog,
  Divider,
  ListItem,
  AlertTitle,
  Pagination,
  Typography,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { PageOptions } from 'src/services/api.types';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { useGetReferenceRequestsByApplicationQuery } from 'src/services/references/references.service';
import { ReferenceRequestStatus, ReferenceRequestResponse } from 'src/services/references/references.types';

import Label from 'src/components/label';
import EmptyContent from 'src/components/empty-content';

import ReviewRequestModal from 'src/sections/jobs/applications/modals/references/review-request-modal';
import SubmitRefereeModal from 'src/sections/jobs/applications/modals/references/submit-referee-modal';

import { TenantType } from 'src/types/enums';

type Props = {
  open: boolean;
  applicationId: string;
  onClose: VoidFunction;
};

enum TabValue {
  PENDING = 'pending',
  COMPLETED = 'completed'
}

export default function ReferenceRequestListModal({ open, applicationId, onClose }: Props) {
  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const dispatch = useAppDispatch();

  const submitRefereeModalControl = useBoolean();

  const reviewRequestModalControl = useBoolean();

  const [selectedRequest, setSelectedRequest] = useState<string | null>(null);

  const [selectedTab, setSelectedTab] = useState<TabValue>(TabValue.PENDING);

  const [pageOptions, setPageOptions] = useState<PageOptions>({
    page: 1,
    per_page: 5,
  });

  const { currentData: referenceRequests } = useGetReferenceRequestsByApplicationQuery({
    applicationId,
    status: selectedTab as TabValue,
    params: pageOptions,
  });

  const onChangePage = useCallback((value: number) => {
    setPageOptions((prev) => ({
      ...prev,
      page: value,
    }));
  }, []);

  const getStatusLabelText = (request: ReferenceRequestResponse) => {
    switch (request.status) {
      case ReferenceRequestStatus.PENDING_APPROVAL:
        return t('applications.references.request_list_modal.status.pending_approval');
      case ReferenceRequestStatus.PENDING_REFEREES:
        return t('applications.references.request_list_modal.status.pending_referees');
      case ReferenceRequestStatus.SENT:
        return t('applications.references.request_list_modal.status.sent');
      case ReferenceRequestStatus.COMPLETED:
        return t('applications.references.request_list_modal.status.completed');
      case ReferenceRequestStatus.EXPIRED:
        return t('applications.references.request_list_modal.status.expired');
      case ReferenceRequestStatus.REJECTED:
        return t('applications.references.request_list_modal.status.rejected');
      default:
        return '';
    }
  };

  const onSubmitReferee = useCallback((referenceRequestId: string) => {
    setSelectedRequest(referenceRequestId);
    submitRefereeModalControl.onTrue();
  }, [submitRefereeModalControl]);

  const onCloseSubmitRefereeModal = useCallback(() => {
    setSelectedRequest(null);
    submitRefereeModalControl.onFalse();
  }, [submitRefereeModalControl]);

  const onReviewRequest = useCallback((referenceRequestId: string) => {
    setSelectedRequest(referenceRequestId);
    reviewRequestModalControl.onTrue();
  }, [reviewRequestModalControl]);

  const onCloseReviewRequestModal = useCallback(() => {
    setSelectedRequest(null);
    reviewRequestModalControl.onFalse();
  }, [reviewRequestModalControl]);

  const renderActionButton = useCallback((request: ReferenceRequestResponse) => {
    if (request.status === ReferenceRequestStatus.PENDING_APPROVAL && tenantType === TenantType.Recruiter) {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onReviewRequest(request.id)}
          size='small'
        >
          {t('common.review')}
        </Button>
      );
    }

    if (request.status === ReferenceRequestStatus.PENDING_REFEREES && tenantType === TenantType.Candidate) {
      return (
        <Button
          variant="contained"
          color="primary"
          size='small'
          onClick={() => onSubmitReferee(request.id)}
        >
          {t('applications.references.request_list_modal.labels.submit_referee')}
        </Button>
      );
    }

    return null;
  }, [tenantType, onSubmitReferee, onReviewRequest, t]);

  const changeTab = (_e: React.SyntheticEvent, value: TabValue) => {
    setSelectedTab(value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('applications.references.request_list_modal.title')}</DialogTitle>
      <DialogContent>

        {
          (!!referenceRequests?.count && tenantType === TenantType.Candidate) && (
            <Alert severity="info">
              <AlertTitle>
                <Typography variant="subtitle2">
                  {t('applications.references.request_list_modal.labels.notes_title')}
                </Typography>
              </AlertTitle>

              <Typography variant="body2">
                {_.capitalize(referenceRequests.results[0]?.notes)}
              </Typography>

            </Alert>
          )
        }

        <Tabs
          value={selectedTab}
          onChange={changeTab}
          aria-label="Reference request tabs"
          role="navigation"
        >
          <Tab label={t('applications.references.request_list_modal.tabs.pending')} value={TabValue.PENDING} />
          <Tab label={t('applications.references.request_list_modal.tabs.completed')} value={TabValue.COMPLETED} />
        </Tabs>

        <Stack
          direction="column"
          sx={{ minHeight: 400, height: '100%', typography: 'body1', mt: 2 }}
          justifyContent="space-between"
        >
          {referenceRequests?.count === 0 ? (
            <EmptyContent
              filled
              title={t('applications.references.request_list_modal.no_requests')}
              sx={{
                py: 10,
              }}
            />
          ) : (
            <List sx={{ paddingBottom: 2, maxWidth: '100%' }}>
              {referenceRequests?.results.map((referenceRequest) => (
                <Fragment key={referenceRequest.id}>
                  <ListItem
                    sx={{ paddingY: 1 }}
                    secondaryAction={
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Label color="info">{getStatusLabelText(referenceRequest)}</Label>
                        {renderActionButton(referenceRequest)}
                      </Stack>
                    }
                  >
                    <ListItemText primary="New Reference Request" secondary={
                      <Typography variant="caption" color="textSecondary">
                        {moment(referenceRequest.created_at).format('DD/MM/YYYY hh:mm A')}
                      </Typography>
                    } />
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
          )}

          <Stack flexDirection="row" justifyContent="center" sx={{ py: 2 }}>
            <Pagination
              page={pageOptions.page || 1}
              count={Math.ceil((referenceRequests?.count || 1) / pageOptions.per_page)}
              onChange={(_e, value) => onChangePage(value)}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>

      {
        submitRefereeModalControl.value && (
          <SubmitRefereeModal
            open={submitRefereeModalControl.value}
            referenceRequestId={selectedRequest as string}
            onClose={onCloseSubmitRefereeModal}
          />
        )
      }

      {
        reviewRequestModalControl.value && (
          <ReviewRequestModal
            open={reviewRequestModalControl.value}
            referenceRequestId={selectedRequest as string}
            onClose={onCloseReviewRequestModal}
          />
        )
      }

    </Dialog>
  );
}
