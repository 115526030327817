import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { NumberInputProps } from '@mui/base';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

import { CustomNumberInput } from 'src/components/number-input/number-input';

type Props = NumberInputProps & {
  name: string;
  label?: string;
  helperText?: string;
};

export default function RHFNumberInput({
  name,
  label,
  helperText,
  min,
  max,
  disabled,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel
            label={label}
            slotProps={{ typography: { variant: 'body2' } }}
            sx={{ alignItems: 'start', width: '100%', m: 0 }}
            labelPlacement="top"
            control={
              <CustomNumberInput
                {...field}
                min={min}
                max={max}
                onChange={(event, value) => {
                  field.onChange(value);
                  if (other.onChange) {
                    other.onChange(event, value);
                  }
                }}
                value={field.value}
                disabled={disabled}
                {...other}
              />
            }
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}
