import moment from 'moment';
import { useRef, useMemo } from 'react';

import i18n from 'src/locales/i18n';
import { JobInvite } from 'src/services/jobs/jobs.types';
import { useGetJobInvitesByJobQuery } from 'src/services/jobs/jobs.service';
import { useGetJobCandidatesQuery } from 'src/services/applications/applications.service';
import { Application, ApplicationStage } from 'src/services/applications/applications.types';

import { TenantType } from 'src/types/enums';
import { CardType, IKanbanColumn, IKanbanApplicationCard } from 'src/types/kanban';

// ----------------------------------------------------------------------

export const RecruiterApplicationStages = [
  ApplicationStage.INVITED,
  ApplicationStage.SCREENING,
  ApplicationStage.INTERNAL_INTERVIEW,
  ApplicationStage.READY_TO_SHORTLIST,
  ApplicationStage.SHORTLISTED,
  ApplicationStage.READY_TO_INTERVIEW,
  ApplicationStage.INTERVIEW,
  ApplicationStage.REFERENCE_CHECK,
  ApplicationStage.READY_TO_OFFER, // No candidate notification
  ApplicationStage.OFFER,
  ApplicationStage.OFFER_ACCEPTED,
  ApplicationStage.TO_BE_REJECTED,
  ApplicationStage.REJECTED,
  ApplicationStage.WITHDRAWN,
];

export const ClientApplicationStages = [
  ApplicationStage.INVITED,
  ApplicationStage.SHORTLISTED,
  ApplicationStage.INTERVIEW,
  ApplicationStage.REFERENCE_CHECK,
  ApplicationStage.READY_TO_OFFER,
  ApplicationStage.OFFER,
  ApplicationStage.OFFER_ACCEPTED,
  ApplicationStage.TO_BE_REJECTED,
  ApplicationStage.REJECTED,
  ApplicationStage.WITHDRAWN,
];

export const ApplicationStageDivergence = RecruiterApplicationStages.filter(
  (stage) => !ClientApplicationStages.includes(stage)
);

export type BoardConfig = {
  board: { [key: string]: IKanbanColumn<IKanbanApplicationCard> } | null | undefined;
  boardLoading: boolean;
  boardError: boolean;
  boardEmpty: boolean;
};

export const TenantToColumnMap = {
  [TenantType.Recruiter]: RecruiterApplicationStages,
  [TenantType.Client]: ClientApplicationStages,
};

const generateBoard = (
  applications: Application[],
  invites: JobInvite[],
  tenant: TenantType
): { [key: string]: IKanbanColumn<IKanbanApplicationCard> } => {
  // TODO: Merge candidate appplications and jobs
  const applicationsMap: Record<
    string,
    IKanbanColumn<IKanbanApplicationCard>
  > = RecruiterApplicationStages.reduce(
    (acc, stage: ApplicationStage, index: number) => {
      acc[stage] = {
        id: `${stage}`,
        name: i18n.t(`enums.application_status.${stage}`),
        tasks: [],
        stage,
      };

      return acc;
    },
    {} as { [key: string]: IKanbanColumn<IKanbanApplicationCard> }
  );

  // eslint-disable-next-line no-restricted-syntax
  for (let i = 0; i < applications.length; i++) {
    const { id, attached_profile, stage, requested_stage, manager_type, recruiter, client, rating } =
      applications[i];

    const task: IKanbanApplicationCard = {
      id,
      index: i,
      candidateName: `${attached_profile.first_name} ${attached_profile.last_name}`,
      recruiterName: recruiter?.company_profile?.name,
      type: CardType.Application,
      positionTitle: attached_profile.position_title,
      rating,
      avatarUrl: attached_profile.profile_image?.public_path,
      stage,
      requestedStage: requested_stage,
      isExternallyManaged: tenant === TenantType.Client && manager_type === TenantType.Recruiter,
      hasAttachedClient: !!client,
    };

    if (tenant !== TenantType.Candidate) {
      if (requested_stage) {
        applicationsMap[requested_stage]?.tasks.push(task);
      } else {
        applicationsMap[stage]?.tasks.push(task);
      }
    } else {
      applicationsMap[stage]?.tasks.push(task);
    }
  }

  // invites
  for (let i = 0; i < invites.length; i++) {
    const { id, first_name, last_name, job, created_at } = invites[i];

    const task: IKanbanApplicationCard = {
      id,
      index: i,
      candidateName: `${first_name} ${last_name}`,
      recruiterName: job.recruiter_company_name,
      type: CardType.Job_Invite,
      positionTitle: moment(created_at).format('DD/MM/YYYY HH:mm'),
      avatarUrl: undefined,
      stage: ApplicationStage.INVITED,
      requestedStage: undefined,
      isExternallyManaged: false,
      hasAttachedClient: true,
    };

    applicationsMap[ApplicationStage.INVITED].tasks.push(task);
  }

  return applicationsMap;
};

export function useGetJobApplicationsBoard(jobId: string, currentTenant: TenantType) {
  const {
    currentData: jobApplications,
    isLoading,
    isError,
  } = useGetJobCandidatesQuery(
    {
      jobId,
      params: {
        page: 1,
        per_page: 999,
      },
    },
    {
      refetchOnFocus: true,
      refetchOnReconnect: true,
      skip: !jobId,
    }
  );

  const { currentData: jobInvites } = useGetJobInvitesByJobQuery(
    {
      jobId,
      page: 1,
      perPage: 999,
    },
    {
      refetchOnFocus: true,
      refetchOnReconnect: true,
      skip: !jobId,
    }
  );

  const boardRef = useRef<BoardConfig | null>(null);

  const memoizedValue = useMemo(
    () => {
      const returnValue: BoardConfig = {
        board: jobApplications?.results
          ? generateBoard(jobApplications?.results ?? [], jobInvites?.results ?? [], currentTenant)
          : boardRef.current?.board,
        boardLoading: isLoading,
        boardError: isError,
        boardEmpty: !isLoading && !jobApplications?.count,
      };

      boardRef.current = returnValue;

      return returnValue;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobApplications?.results, jobInvites?.results, isError, isLoading, currentTenant]
  );

  return memoizedValue;
}
