
import TableRow from '@mui/material/TableRow';
import { Stack, useTheme } from '@mui/system';
import TableCell from '@mui/material/TableCell';
import { MenuItem, IconButton, Typography } from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useTranslate } from 'src/locales';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { ICandidateReferee } from 'src/services/candidates/candidates.types';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';

// ----------------------------------------------------------------------

type Props = {
  row: ICandidateReferee;
  onViewRequests: (refereeId: string) => void;
};

export default function ProfileRefereeTableRow({
  row,
  onViewRequests
}: Props) {

  const confirm = useBoolean();

  const popover = usePopover();

  const { t } = useTranslate();

  const tenantType = useOrgTenant();

  const theme = useTheme();

  return (
    <TableRow hover>
      <TableCell>

        <Stack direction="column" alignItems="start" mb={1}>
          <TruncatedTextField
            text={`${row.first_name} ${row.last_name}`}
            mode="characters"
            limit={25}
            onClick={() => onViewRequests(row.id)}
            showMoreEnabled={false}
            variant='body2'
            fontWeight="bold"
            sx={{
              '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline'
              }
            }}
            style={{ textTransform: 'capitalize' }}
          />
          <Typography variant='caption' sx={{ color: theme.palette.text.primary }}>
            {row.company_name}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {row.email}
      </TableCell>
      <TableCell>
        {row.phone}
      </TableCell>
      <TableCell>
        {row.referee_position}
      </TableCell>

      <TableCell>
        <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
          <Iconify icon="eva:more-horizontal-fill" />
        </IconButton>

      </TableCell>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
      >
        <MenuItem
          onClick={() => onViewRequests(row.id)}
        >
          <Iconify icon="solar:eye-bold" />
          {t('common.view')}
        </MenuItem>

      </CustomPopover>

    </TableRow >
  );
}
