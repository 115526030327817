import * as Yup from 'yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  Stack,
  Divider,
  useTheme,
  CardHeader,
  Typography,
  CardContent,
  useMediaQuery,
  FormHelperText
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import {
  useGetOrganizationSettingsQuery,
  useUpdateOrganizationSettingsMutation,
} from 'src/services/organisation/organisation.service';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFCheckbox } from 'src/components/hook-form';
import RHFNumberInput from 'src/components/hook-form/rhf-number-input';

import { useFeatures } from 'src/sections/billing/usage/hooks/use-features';


export default function BusinessSettingsCard() {

  const { t } = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const authContext = useAuthContext();

  const { currentData: settingsData, isLoading: isLoadingSettings } = useGetOrganizationSettingsQuery(
    authContext.organization.id
  );

  const [updateSettings] = useUpdateOrganizationSettingsMutation();

  const { canAccess } = useFeatures();

  const OrgSettingsSchema = Yup.object().shape({
    stop_gap_hours: Yup.number().min(1, t('validation.min_zero')).required(t('validation.required')),
    reference_template_questions_editable: Yup.boolean(),
  });

  const methods = useForm({
    resolver: yupResolver(OrgSettingsSchema),
    defaultValues: {
      stop_gap_hours: 1,
      reference_template_questions_editable: false
    },
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  useEffect(() => {
    if (settingsData) {
      const stopGapSetting = settingsData.find(
        (setting) => setting.setting_type === 'stop_gap_hours'
      );

      if (stopGapSetting) {
        setValue('stop_gap_hours', stopGapSetting.setting_value as number);
      }

      const referenceQuestionSetting = settingsData.find(
        (setting) => setting.setting_type === 'reference_template_questions_editable'
      );

      if (referenceQuestionSetting) {
        setValue('reference_template_questions_editable', referenceQuestionSetting.setting_value as boolean);
      }

    }
  }, [settingsData, setValue]);

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const settingsToUpdate = [];

      if (!settingsData) return;

      const stopGapSetting = settingsData.find(
        (setting) => setting.setting_type === 'stop_gap_hours'
      );

      if (stopGapSetting) {
        settingsToUpdate.push({
          id: stopGapSetting.id,
          value: data.stop_gap_hours,
        });
      }

      const referenceQuestionSetting = settingsData.find(
        (setting) => setting.setting_type === 'reference_template_questions_editable'
      );

      if (referenceQuestionSetting) {
        settingsToUpdate.push({
          id: referenceQuestionSetting.id,
          value: data.reference_template_questions_editable
        });
      }

      if (settingsToUpdate.length === 0) return;

      await updateSettings({
        organizationId: authContext.organization.id,
        settings: settingsToUpdate,
      }).unwrap();

      enqueueSnackbar(t('admin-organization.settings.business_settings.update.success'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('admin-organization.settings.business_settings.update.default_error'), { variant: 'error' });
    }
  });

  if (isLoadingSettings) {
    return (
      <Box sx={{ width: '100%' }}>
        <Card>
          <CardContent>
            <Typography>{t('common.loading')}</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <FormProvider methods={methods}>
      <Card sx={{ width: '100%', boxShadow: 0 }}>
        <CardHeader
          avatar={
            <Iconify
              icon="mdi:shop-settings"
              sx={{
                width: 30,
                height: 30,
                color: theme.palette.primary.main,
              }}
            />
          }
          title={
            <Typography variant="h6">{t('admin-organization.settings.business_settings.title')}</Typography>
          }
        />

        <CardContent>
          <Stack spacing={2} sx={{ px: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RHFNumberInput
                  min={1}
                  name="stop_gap_hours"
                  label={t('admin-organization.settings.business_settings.labels.stop_gap_hours')}
                  placeholder={t('common.placeholders.stop_gap_hours')}
                  helperText={t('admin-organization.settings.business_settings.labels.stop_gap_hours_helper')}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <RHFCheckbox
                  name="reference_template_questions_editable"
                  label={
                    <Stack gap={0}>
                      <Typography variant="body2">
                        {t('admin-organization.settings.business_settings.labels.reference_templates_editable')}
                      </Typography>
                      <FormHelperText sx={{ mt: 0 }}>
                        {t('admin-organization.settings.business_settings.labels.reference_templates_editable_helper')}
                      </FormHelperText>
                    </Stack>
                  }
                />
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
              <LoadingButton
                type="submit"
                onClick={onSubmit}
                variant="contained"
                loading={isSubmitting}
                disabled={!isValid}
              >
                {t('common.update')}
              </LoadingButton>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </FormProvider>
  );
}