import _ from 'lodash';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Alert,
  Button,
  Dialog,
  Divider,
  MenuItem,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress
} from "@mui/material";

import { useTranslate } from "src/locales";
import { PageOptions } from "src/services/api.types";
import { useAuthContext } from "src/auth/hooks/useAuthContext";
import { ICandidateReferee } from "src/services/candidates/candidates.types";
import { generateSubmitRefereesError } from 'src/services/references/references.utils';
import {
  useGetMyProfileQuery,
  useGetProfileRefereesQuery
} from "src/services/candidates/candidates.service";
import {
  useSubmitRefereesMutation,
  useGetReferenceRequestByIdQuery
} from "src/services/references/references.service";

import {
  RHFSelect,
  RHFTextField
} from 'src/components/hook-form';

type Props = {
  open: boolean;
  referenceRequestId: string;
  onClose: VoidFunction;
};

type FormValues = {
  refereeId?: string | null;  // Added refereeId to FormValues
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  relationship: string;
  position_title: string;
  company_name: string;
  referee_position: string;
  work_experience_id?: string | null;
};

const DEFAULT_PAGE_OPTIONS: PageOptions = {
  page: 1,
  per_page: 25,
};

export default function SubmitRefereeModal({ open, referenceRequestId, onClose }: Props) {
  const { t } = useTranslate();
  const authContext = useAuthContext();

  const [submitReference, { isLoading: isSubmitting }] = useSubmitRefereesMutation();

  const [refereePageOptions] = useState<PageOptions>({
    page: 1,
    per_page: 5
  });

  const { currentData: referenceRequest, isLoading: loadingRequest } = useGetReferenceRequestByIdQuery({
    referenceRequestId
  }, {
    skip: !referenceRequestId
  });

  const { currentData: primaryProfile } = useGetMyProfileQuery();

  const {
    currentData: cpReferees, isLoading: loadingCpReferees } = useGetProfileRefereesQuery({
      profileId: primaryProfile?.id as string,
      pageOptions: refereePageOptions
    }, {
      skip: !primaryProfile
    });

  const SubmitRefereeSchema = Yup.object().shape({
    refereeId: Yup.string().nullable(),
    first_name: Yup.string().required(t('validation.required')),
    last_name: Yup.string().required(t('validation.required')),
    email: Yup.string().email(t('validation.email')).required(t('validation.required')),
    phone: Yup.string().required(t('validation.required')),
    relationship: Yup.string().required(t('validation.required')),
    position_title: Yup.string().required(t('validation.required')),
    company_name: Yup.string().required(t('validation.required')),
    referee_position: Yup.string().required(t('validation.required')),
    work_experience_id: Yup.string().nullable(),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(SubmitRefereeSchema),
    defaultValues: {
      refereeId: null as string | null,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      relationship: '',
      position_title: '',
      company_name: '',
      referee_position: '',
      work_experience_id: null as string | null
    },
  });

  const {
    handleSubmit,
    watch,
    setValue
  } = methods;

  const refereeId = watch('refereeId');

  // Effect to populate fields when referee is selected
  useEffect(() => {
    if (refereeId && cpReferees) {
      const selectedReferee = cpReferees.results.find(referee => referee.id === refereeId);
      if (selectedReferee) {
        setValue('first_name', selectedReferee.first_name || '');
        setValue('last_name', selectedReferee.last_name || '');
        setValue('email', selectedReferee.email || '');
        setValue('phone', selectedReferee.phone || '');
        setValue('relationship', selectedReferee.relationship || '');
        setValue('position_title', selectedReferee.position_title || '');
        setValue('company_name', selectedReferee.company_name || '');
        setValue('referee_position', selectedReferee.referee_position || '');
      }
    } else {
      // Clear fields when no referee is selected
      setValue('first_name', '');
      setValue('last_name', '');
      setValue('email', '');
      setValue('phone', '');
      setValue('relationship', '');
      setValue('position_title', '');
      setValue('company_name', '');
      setValue('referee_position', '');
      setValue('work_experience_id', null);
    }
  }, [refereeId, cpReferees, setValue]);

  const onSubmit = async (data: FormValues) => {
    try {
      await submitReference({
        referenceRequestId,
        applicationId: referenceRequest?.group.application.id as string,
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          relationship: data.relationship,
          position_title: data.position_title,
          company_name: data.company_name,
          referee_position: data.referee_position,
          work_experience_id: data.work_experience_id as string
        }
      }).unwrap();

      enqueueSnackbar(t('applications.references.submit_referee_modal.api.success'), { variant: 'success' });
      onClose();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(generateSubmitRefereesError(error), { variant: 'error' });
    }
  };

  const isLoading = loadingRequest || loadingCpReferees;

  const workExperiences = useMemo(() => {
    if (primaryProfile) {
      return primaryProfile.work_experiences?.map((workExperience) => ({
        id: workExperience.id,
        position_title: workExperience.position_title,
        company_name: workExperience.company_name,
      }));
    }
    return [];
  }, [primaryProfile]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {t('applications.references.submit_referee_modal.title')}
      </DialogTitle>
      <DialogContent sx={{ pb: 5 }}>
        {isLoading ? (
          <CircularProgress sx={{ display: 'block', mx: 'auto', my: 2 }} />
        ) : (
          <FormProvider {...methods}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                {
                  referenceRequest?.notes && (
                    <Alert severity="info">
                      {_.capitalize(referenceRequest?.notes)}
                    </Alert>
                  )
                }
              </Grid>

              <Grid item xs={12}>
                <RHFSelect
                  name="refereeId"
                  label={t('applications.references.submit_referee_modal.existing_referee')}
                >
                  <MenuItem key={0} value="no-referee">
                    {t('applications.references.submit_referee_modal.new_referee')}
                  </MenuItem>
                  {cpReferees?.results.map((referee: ICandidateReferee) => (
                    <MenuItem key={referee.id} value={referee.id}>
                      {referee.first_name} {referee.last_name} - {_.capitalize(referee.email)}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12}>
                <Divider >
                  <Typography variant='subtitle2'>
                    {t('applications.references.submit_referee_modal.referee_details')}
                  </Typography>
                </Divider>
              </Grid>

              <Grid item xs={6}>
                <RHFTextField
                  name="first_name"
                  label={t('applications.references.submit_referee_modal.first_name')}
                />
              </Grid>
              <Grid item xs={6}>
                <RHFTextField
                  name="last_name"
                  label={t('applications.references.submit_referee_modal.last_name')}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  name="email"
                  label={t('applications.references.submit_referee_modal.email')}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  name="phone"
                  label={t('applications.references.submit_referee_modal.phone')}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  name="relationship"
                  label={t('applications.references.submit_referee_modal.relationship')}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  name="company_name"
                  label={t('applications.references.submit_referee_modal.company_name')}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  name="referee_position"
                  label={t('applications.references.submit_referee_modal.referee_position')}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider >
                  <Typography variant='subtitle2'>
                    {t('applications.references.submit_referee_modal.your_details')}
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <RHFTextField
                  name="position_title"
                  label={t('applications.references.submit_referee_modal.position_title')}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFSelect
                  name="work_experience_id"
                  label={t('applications.references.submit_referee_modal.related_work_experience')}
                >
                  <MenuItem key={0} value={undefined}>
                    {t('common.none')}
                  </MenuItem>
                  {workExperiences?.map((workExperience) => (
                    <MenuItem key={workExperience.id} value={workExperience.id}>
                      {workExperience.position_title} - {workExperience.company_name}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>
            </Grid>
          </FormProvider>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {t('common.cancel')}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isLoading || isSubmitting}
          loading={isSubmitting}
          color="primary"
        >
          {t('common.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}