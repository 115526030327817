import { isString } from "lodash";

import i18n from "src/locales/i18n";

import { BusinessErrorCodes } from "src/types/business-errors";

const STORAGE_KEY = 'referenceFormData';

export const getStoredReferenceData = () => {
  const stored = localStorage.getItem(STORAGE_KEY);
  return stored ? JSON.parse(stored) : null;
};

export const saveStoredReferenceData = (data: any) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

export const clearStoredReferenceData = () => {
  localStorage.removeItem(STORAGE_KEY);
};

export const generateSubmitRefereesError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.RefereeAlreadySubmittedForApplication) {
      return i18n.t('applications.references.submit_referee_modal.api.referee_already_submitted');
    }

    if (e?.data.error_code === BusinessErrorCodes.InvalidReferenceRequestStatus) {
      return i18n.t('applications.references.submit_referee_modal.api.invalid_request_status');
    }
  }
  return i18n.t('applications.references.submit_referee_modal.api.default_error');
};

export const generateRequestReferenceError = (e: any) => {
  if (isString(e?.data?.error_code)) {
    if (e?.data.error_code === BusinessErrorCodes.InvalidApplicationStage || e?.data.error_code === BusinessErrorCodes.ApplicationArchived) {
      return i18n.t('applications.references.initiate_request_modal.api.invalid_application_status');
    }

    if (e?.data.error_code === BusinessErrorCodes.ReferenceTemplatesNotConfigured) {
      return i18n.t('applications.references.initiate_request_modal.api.templates_not_configured');
    }
  }
  
  return i18n.t('applications.references.initiate_request_modal.api.default_error');
};

