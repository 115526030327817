import _ from "lodash";
import * as Yup from 'yup';
import { Trans } from "react-i18next";
import { useParams } from "react-router";
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState, useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { Stack } from "@mui/system";
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Step,
  Alert,
  Avatar,
  Button,
  Divider,
  Stepper,
  Checkbox,
  useTheme,
  StepLabel,
  TextField,
  CardHeader,
  Typography,
  CardContent,
  StepContent,
  CircularProgress,
  FormControlLabel
} from "@mui/material";

import { useTranslate } from "src/locales";
import successAnimation from 'src/assets/lottie/success.json';
import errorAnimation from 'src/assets/lottie/error-cone.json';
import { CompleteReferenceSubmissionDto } from "src/services/references/references.types";
import { getStoredReferenceData, saveStoredReferenceData, clearStoredReferenceData } from 'src/services/references/references.utils';
import { useGetPublicReferenceRequestByIdQuery, useCompleteReferenceSubmissionMutation } from "src/services/references/references.service";

import Iconify from "src/components/iconify";
import { RHFTextField } from "src/components/hook-form";
import AnimationContainer from "src/components/animation/container";
import TruncatedTextField from 'src/components/truncate-text/truncated-text-field';
import TruncatedHTMLRenderer from "src/components/truncate-text/truncated-html-card";

export enum FlowStep {
  CONSENT = 'consent',
  IDENTITY = 'identity',
  REFERENCE = 'reference',
  SUMMARY = 'summary',
}

const STEPS = [
  {
    id: FlowStep.CONSENT,
    label: 'Consent',
    description: 'Provide your consent for data collection and sharing'
  },
  {
    id: FlowStep.IDENTITY,
    label: 'Identity',
    description: 'Confirm your identity information to proceed with the reference request'
  },
  {
    id: FlowStep.REFERENCE,
    label: 'Questions',
    description: 'Please provide your answers to the reference questions'
  },
  {
    id: FlowStep.SUMMARY,
    label: 'Summary',
    description: 'Review your answers and submit the reference'
  }
]

export default function ReferenceLandingView() {

  const theme = useTheme();
  const { reference_request_id } = useParams();
  const { t } = useTranslate();

  const { currentData: referenceRequest, isLoading: loadingRequest, error: requestError } = useGetPublicReferenceRequestByIdQuery(
    { referenceRequestId: reference_request_id as string },
    { skip: !reference_request_id }
  );

  const [completeReference, { isLoading: isSubmitting, error: submitError }] = useCompleteReferenceSubmissionMutation();

  const storedData = getStoredReferenceData();

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [referenceErrors, setReferenceErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [flowStep, setFlowStep] = useState<FlowStep>(FlowStep.CONSENT);
  const [invalidSteps, setInvalidSteps] = useState<FlowStep[]>([]);
  const [workExperienceVerified, setWorkExperienceVerified] = useState(() => (storedData?.workExperienceVerified && !isSubmitted) ? storedData.workExperienceVerified : false);

  const [answers, setAnswers] = useState<{ [key: string]: string }>(() => (storedData?.answers && !isSubmitted) ? storedData.answers : {});

  const [isSharedWithCandidate, setIsSharedWithCandidate] = useState(() => (storedData?.isSharedWithCandidate && !isSubmitted) ? storedData.isSharedWithCandidate : false);

  // Referee's identity information
  const RefereeDetailsSchema = Yup.object().shape({
    first_name: Yup.string().required(t("validation.required")),
    last_name: Yup.string().required(t("validation.required")),
    email: Yup.string().email(t("validation.email_format")).required(t("validation.required")),
    phone: Yup.string().required(t("validation.required")),
    relationship: Yup.string().required(t("validation.required")),
    position_title: Yup.string().required(t("validation.required")),
    company_name: Yup.string().required(t("validation.required")),
    referee_position: Yup.string().required(t("validation.required")),
  });

  const defaultValues = useMemo(() => {
    if (storedData && !isSubmitted) {
      return {
        first_name: storedData.referee?.first_name ? storedData.referee?.first_name : referenceRequest?.referee.first_name ?? "",
        last_name: storedData.referee?.last_name ? storedData.referee?.last_name : referenceRequest?.referee.last_name ?? "",
        email: storedData.referee?.email ? storedData.referee?.email : referenceRequest?.referee.email ?? "",
        phone: storedData.referee?.phone ? storedData.referee?.phone : referenceRequest?.referee.phone ?? "",
        relationship: storedData.referee?.relationship ? storedData.referee?.relationship : referenceRequest?.referee.relationship ?? "",
        position_title: storedData.referee?.position_title ? storedData.referee?.position_title : referenceRequest?.referee.position_title ?? "",
        company_name: storedData.referee?.company_name ? storedData.referee?.company_name : referenceRequest?.referee.company_name ?? "",
        referee_position: storedData.referee?.referee_position ? storedData.referee?.referee_position : referenceRequest?.referee.referee_position ?? ""
      };
    }

    return {
      first_name: referenceRequest?.referee.first_name ?? "",
      last_name: referenceRequest?.referee.last_name ?? "",
      email: referenceRequest?.referee.email ?? "",
      phone: referenceRequest?.referee.phone ?? "",
      relationship: referenceRequest?.referee.relationship ?? "",
      position_title: referenceRequest?.referee.position_title ?? "",
      company_name: referenceRequest?.referee.company_name ?? "",
      referee_position: referenceRequest?.referee.referee_position ?? ""
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceRequest, isSubmitted]);

  const methods = useForm({
    resolver: yupResolver(RefereeDetailsSchema),
    defaultValues,
  });

  const {
    setValue,
    trigger,
    getValues,
    reset
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!isInitialLoad) {
      saveStoredReferenceData({
        referee: getValues(),
        answers,
        isSharedWithCandidate,
        workExperienceVerified,
        flowStep
      });
    }
    setIsInitialLoad(false);
  }, [answers, isSharedWithCandidate, flowStep, getValues, isInitialLoad, workExperienceVerified]);

  const activeStep = useMemo(() => STEPS.findIndex(step => step.id === flowStep), [flowStep]);

  const handleAnswerChange = (questionId: string, value: string) => {
    setAnswers((prev) => {
      const newAnswers = { ...prev, [questionId]: value };
      return newAnswers;
    });

    if (referenceErrors[questionId] && value.trim()) {
      setReferenceErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[questionId];
        return newErrors;
      });
    }
  }

  const validateReferenceInput = () => {
    if (!referenceRequest) return false;

    const newErrors: { [key: string]: string } = {};
    referenceRequest.questions.forEach((question) => {
      if (question.required && !answers[question.id]?.trim()) {
        newErrors[question.id] = t(
          "applications.references.landing_page.errors.required"
        );
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setReferenceErrors(newErrors);
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!referenceRequest) return;

    const invalidStepsTest = await validateSteps();
    if (invalidStepsTest.length > 0) return;

    setReferenceErrors({});

    const submissionData: CompleteReferenceSubmissionDto = {
      share_with_candidate: isSharedWithCandidate,
      experience_verified: workExperienceVerified,
      submissions: referenceRequest.questions.map((question) => ({
        question_id: question.id,
        answer: answers[question.id] || "",
      })),
      referee_details: {
        first_name: getValues("first_name"),
        last_name: getValues("last_name"),
        phone: getValues("phone"),
        relationship: getValues("relationship"),
        position_title: getValues("position_title"),
        company_name: getValues("company_name"),
        referee_position: getValues("referee_position")
      }
    };

    try {
      // Submit the reference
      await completeReference({
        referenceRequestId: reference_request_id as string,
        data: submissionData,
      })
        .unwrap();

      setIsSubmitted(true);
      clearStoredReferenceData(); // Clear localStorage on successful submission
    }
    catch (error) {
      console.log(error);
      enqueueSnackbar(t("applications.references.landing_page.submit_error"), { variant: "error" });
    }
  };

  // loading state
  if (loadingRequest) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  // error state
  if (requestError) {
    return (
      <Stack justifyContent="center" alignItems="center" width="100%">
        <AnimationContainer height={200} width={200} json={errorAnimation} loop={false} />
        <Typography variant="h6" >
          {t("applications.references.landing_page.request_error_title")}
        </Typography>
        <Typography variant="body2" >
          {t("applications.references.landing_page.request_error_subtitle")}
        </Typography>
      </Stack>
    );
  }

  // ensure referenceRequest is available
  if (!referenceRequest) return null;

  const renderTitle = () => {
    switch (flowStep) {
      case FlowStep.CONSENT:
        return t("applications.references.landing_page.consent_title");
      case FlowStep.IDENTITY:
        return t("applications.references.landing_page.identity_title");
      case FlowStep.REFERENCE:
        return t("applications.references.landing_page.questions_title");
      case FlowStep.SUMMARY:
        return t("applications.references.landing_page.summary_title");
      default:
        return "";
    }
  }

  const renderNextButton = () => {
    switch (flowStep) {
      case FlowStep.CONSENT:
        return <Button
          variant="contained"
          onClick={handleNext}
        >
          {t("applications.references.landing_page.consent_btn_text")}
        </Button>
      case FlowStep.IDENTITY:
        return <Button
          variant="contained"
          onClick={handleNext}
        >
          {t("common.confirm")}
        </Button>
      case FlowStep.REFERENCE:
        return <Button
          variant="contained"
          onClick={handleNext}
        >
          {t("common.next")}
        </Button>
      case FlowStep.SUMMARY:
        return (
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            onClick={handleNext}
          >
            {t("common.submit")}
          </LoadingButton>
        )
      default:
        return null;
    }
  }

  const renderFlowStep = () => {

    switch (flowStep) {
      case FlowStep.CONSENT:
        return (
          <Card sx={{ p: 4 }}>
            <Typography variant="body1" textAlign="left">
              <Trans
                i18nKey="somelink"
                components={{
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  anchorLink: <a href="https://www.jobtetris.com/privacy-policy" target="_blank" rel="noreferrer" />,
                }}
              >
                {t("applications.references.landing_page.consent_notice",)}
              </Trans>
            </Typography>
            <Stack direction="row" justifyContent="space-between" mt={2}>

              <FormControlLabel
                label={t("applications.references.landing_page.share_with_candidate")}
                control={
                  <Checkbox
                    checked={isSharedWithCandidate}
                    onChange={(e) => setIsSharedWithCandidate(e.target.checked)}
                  />
                }
              />
            </Stack>
          </Card>
        )
        break;
      case FlowStep.IDENTITY:
        return (
          // allow referee to change details here
          <Card sx={{ p: 4 }}>
            <Stack direction="column" gap={2}>
              <Typography variant="subtitle2" textAlign="left">
                {t("applications.references.landing_page.referee_information")}
              </Typography>
              <FormProvider {...methods}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <RHFTextField
                      name="first_name"
                      label={t("applications.references.landing_page.labels.first_name")}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTextField
                      name="last_name"
                      label={t("applications.references.landing_page.labels.last_name")}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RHFTextField
                      name="email"
                      disabled
                      label={t("applications.references.landing_page.labels.email")}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RHFTextField
                      name="phone"
                      label={t("applications.references.landing_page.labels.phone")}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RHFTextField
                      name="relationship"
                      label={t("applications.references.landing_page.labels.relationship")}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RHFTextField
                      name="position_title"
                      label={t("applications.references.landing_page.labels.position_title")}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RHFTextField
                      name="company_name"
                      label={t("applications.references.landing_page.labels.company_name")}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RHFTextField
                      name="referee_position"
                      label={t("applications.references.landing_page.labels.referee_position")}
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </Stack>

            {/* Work experience verification */}
            {
              referenceRequest.work_experience && (
                <Stack direction="column" gap={2} mt={3}>
                  <Typography variant="subtitle2" textAlign="left">
                    {t("applications.references.landing_page.work_experience_verification_notice")}
                  </Typography>
                  <Card
                    className="draggable-experience-card"
                    elevation={1}
                    sx={{
                      backgroundColor: theme.palette.background.default,
                      textAlign: 'left'
                    }}
                  >
                    <CardHeader
                      sx={{
                        flexWrap: 'wrap',
                      }}
                      title={referenceRequest.work_experience?.position_title}
                      subheader={referenceRequest.work_experience?.company_name}
                      avatar={
                        <Avatar src="" alt={referenceRequest.work_experience?.company_name}>
                          {referenceRequest.work_experience?.company_name?.charAt(0)}
                        </Avatar>
                      }
                    />
                    <CardContent>
                      <Grid container wrap="wrap">
                        <Grid xs={12} md={6}>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {referenceRequest.work_experience?.duration_text}
                          </Typography>
                        </Grid>
                        <Grid xs={12} pt={1}>
                          <TruncatedHTMLRenderer html={referenceRequest.work_experience?.role_summary as string} mode="lines" limit={3} />
                        </Grid>

                        <Grid xs={12} pt={1}>
                          <Divider />
                          <FormControlLabel
                            label={t("applications.references.landing_page.work_experience_verified")}
                            sx={{ mt: 2 }}
                            control={
                              <Checkbox
                                checked={workExperienceVerified}
                                onChange={(e) => setWorkExperienceVerified(e.target.checked)}
                              />
                            }
                          />
                        </Grid>
                      </Grid>

                    </CardContent>
                  </Card>
                </Stack>
              )
            }

          </Card>
        )
        break;
      case FlowStep.REFERENCE:
        return (
          <Card sx={{ p: 4 }}>
            <Grid container spacing={8}>
              {referenceRequest.questions.map((question) => (
                <Grid item xs={12} key={question.id}>
                  <Typography variant="subtitle1" gutterBottom textAlign="left">
                    {question.question}{" "}
                    {question.required && (
                      <span style={{ color: "red" }}>*</span>
                    )}
                  </Typography>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    value={answers[question.id] || ""}
                    onChange={(e) =>
                      handleAnswerChange(question.id, e.target.value)
                    }
                    error={!!referenceErrors[question.id]}
                    helperText={referenceErrors[question.id]}
                    placeholder={t(
                      "applications.references.landing_page.answer_placeholder"
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          </Card>
        )
        break;
      case FlowStep.SUMMARY:
        return (
          <Card sx={{ p: 4 }}>

            <Stack direction="column" gap={2}>
              <Typography variant="h6" textAlign="left">
                {t("applications.references.landing_page.summary_title")}
              </Typography>
              <Stack direction="column" gap={2}>
                <Typography variant="body1" textAlign="left">
                  {t("applications.references.landing_page.summary_notice")}
                </Typography>

                {
                  isSharedWithCandidate ? (
                    <Alert severity="success">
                      {t("applications.references.landing_page.share_with_candidate_notice")}
                    </Alert>
                  )
                    :
                    (
                      <Alert severity="warning">
                        {t("applications.references.landing_page.not_shared_with_candidate_notice")}
                      </Alert>
                    )
                }

              </Stack>
              <Divider sx={{ my: 2 }} />
              <Stack direction="column" gap={2}>
                <Typography variant="h6" textAlign="left">
                  {t("applications.references.landing_page.identity_title")}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label={t("applications.references.landing_page.labels.first_name")}
                      value={getValues("first_name")}
                      disabled
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label={t("applications.references.landing_page.labels.last_name")}
                      value={getValues("last_name")}
                      disabled
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("applications.references.landing_page.labels.email")}
                      value={getValues("email")}
                      disabled
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("applications.references.landing_page.labels.phone")}
                      value={getValues("phone")}
                      disabled
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("applications.references.landing_page.labels.position_title")}
                      value={getValues("position_title")}
                      disabled
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("applications.references.landing_page.labels.relationship")}
                      value={getValues("relationship")}
                      disabled
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("applications.references.landing_page.labels.company_name")}
                      value={getValues("company_name")}
                      disabled
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("applications.references.landing_page.labels.referee_position")}
                      value={getValues("referee_position")}
                      disabled
                      fullWidth
                      required
                    />
                  </Grid>

                </Grid>
              </Stack>

              <Divider sx={{ my: 2 }} />

              {
                referenceRequest.work_experience && (
                  <>
                    <Stack direction="column" gap={2}>
                      <Typography variant="h6" textAlign="left">
                        {t("applications.references.landing_page.work_experience_verification_title")}
                      </Typography>
                      <Card
                        className="draggable-experience-card"
                        elevation={1}
                        sx={{
                          backgroundColor: theme.palette.background.default,
                          textAlign: 'left'
                        }}
                      >
                        <CardHeader
                          sx={{
                            flexWrap: 'wrap',
                          }}
                          title={referenceRequest.work_experience?.position_title}
                          subheader={referenceRequest.work_experience?.company_name}
                          avatar={
                            <Avatar src="" alt={referenceRequest.work_experience?.company_name}>
                              {referenceRequest.work_experience?.company_name?.charAt(0)}
                            </Avatar>
                          }
                        />
                        <CardContent>
                          <Grid container wrap="wrap">
                            <Grid xs={12} md={6}>
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {referenceRequest.work_experience?.duration_text}
                              </Typography>
                            </Grid>
                            <Grid xs={12} pt={1}>
                              <TruncatedHTMLRenderer html={referenceRequest.work_experience?.role_summary as string} mode="lines" limit={3} />
                            </Grid>

                            <Grid xs={12} pt={1}>
                              <Divider sx={{ my: 2 }} />
                              {
                                workExperienceVerified ? (
                                  <Alert severity="success">
                                    {t("applications.references.landing_page.work_experience_verified_notice")}
                                  </Alert>
                                )
                                  :
                                  (
                                    <Alert severity="warning">
                                      {t("applications.references.landing_page.work_experience_not_verified_notice")}
                                    </Alert>
                                  )
                              }
                            </Grid>
                          </Grid>

                        </CardContent>
                      </Card>
                    </Stack>

                    <Divider sx={{ my: 2 }} />
                  </>

                )
              }

              <Stack direction="column" gap={2}>
                <Typography variant="h6" textAlign="left">
                  {t("applications.references.landing_page.questions_title")}
                </Typography>
                {referenceRequest.questions.map((question) => (
                  <Stack direction="column" gap={1} key={question.id}>
                    <Typography variant="body1" textAlign="left" sx={{ fontWeight: 600 }}>
                      {question.question}
                    </Typography>
                    <Typography variant="body2" textAlign="left">
                      {answers[question.id] || t("applications.references.landing_page.no_answer")}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Card>
        )
        break;
      default:
        return null;
    }

  }

  const handleNext = () => {

    const currentStep = STEPS[activeStep];

    switch (currentStep.id) {
      case FlowStep.CONSENT: {
        setFlowStep(FlowStep.IDENTITY);
        break;
      }
      case FlowStep.IDENTITY: {
        trigger().then((isValid) => {
          if (isValid) {
            setFlowStep(FlowStep.REFERENCE);
          }
        });
        break;
      }
      case FlowStep.REFERENCE: {
        const isReferenceValid = validateReferenceInput();
        if (isReferenceValid) {
          setFlowStep(FlowStep.SUMMARY);
        }

        break;
      }
      case FlowStep.SUMMARY: {
        handleSubmit();
        break;
      }
      default:
        break;
    };

    // Scroll to top of the page after each step
    window.scrollTo(0, 0);
  }

  const handleBack = () => {

    if (activeStep > 0) {
      setFlowStep(STEPS[activeStep - 1].id);
    }

  };

  const validateSteps = async () => {
    const invalidStepsCol: FlowStep[] = [];

    const isIdentifyDetailsValid = await trigger();

    if (!isIdentifyDetailsValid) {
      invalidStepsCol.push(FlowStep.IDENTITY);
    }

    const isReferenceValid = validateReferenceInput();
    if (!isReferenceValid) {
      invalidStepsCol.push(FlowStep.REFERENCE);
    }

    setInvalidSteps(invalidStepsCol);
    return invalidStepsCol;
  }

  const isStepInvalid = (step: FlowStep) => invalidSteps.includes(step)

  return (
    <Box
      sx={{
        padding: 2,
        marginX: { xs: 0, lg: 8, xl: 12 },
        display: "flex",
        gap: 2,
        alignItems: "center",
        justifyContent: "center"
      }}
    >

      <Grid container spacing={2} >

        {/* Breadcrumbs section */}
        {
          !isSubmitted && (
            <Grid item xs={12} xl={4} sx={{ mb: 2, width: '100%' }}>
              <Stack sx={{ borderRadius: 1, position: 'sticky', top: 100 }} gap={2}>
                <Card sx={{ p: 2 }}>
                  <Stack direction="column" justifyContent="start" alignItems="start">

                    {
                      referenceRequest.organization.company_logo && (
                        <img
                          src={referenceRequest.organization.company_logo?.public_path}
                          alt={t("applications.references.landing_page.company_logo_alt")}
                          style={{ maxHeight: 150 }}
                        />
                      )
                    }

                    <Stack direction="row" justifyContent="start" alignItems="start" width="100%">
                      <Stack direction="column" justifyContent="start" alignItems="start" width="100%">
                        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                          {t("applications.references.landing_page.requested_by")}
                        </Typography>

                        <Stack direction="row" justifyContent="start" alignItems="center" gap={1}>
                          <Iconify icon="hugeicons:user" />
                          <TruncatedTextField
                            text={`${referenceRequest.manager.first_name} ${referenceRequest.manager.last_name}`}
                            variant="body2"
                            color={theme.palette.text.secondary}
                            mode="characters"
                            textAlign="left"
                            showMoreEnabled={false}
                            limit={20}
                          />
                        </Stack>
                        <Stack direction="row" justifyContent="start" alignItems="center" gap={1}>
                          <Iconify icon="octicon:organization-24" />
                          <TruncatedTextField
                            text={_.capitalize(referenceRequest.organization.company_name)}
                            variant="body2"
                            color={theme.palette.text.primary}
                            mode="characters"
                            textAlign="left"
                            showMoreEnabled={false}
                            limit={20}
                          />
                        </Stack>

                        <Stack direction="row" justifyContent="start" alignItems="center" gap={1}>
                          <Iconify icon="eva:email-outline" />
                          <TruncatedTextField
                            text={
                              <a href={`mailto:${referenceRequest.manager.email}`} style={{ color: theme.palette.text.primary, textAlign: 'left' }}>
                                {_.capitalize(referenceRequest.manager.email)}
                              </a>
                            }
                            variant="body2"
                            color={theme.palette.text.primary}
                            mode="characters"
                            textAlign="left"
                            showMoreEnabled={false}
                            limit={20}
                          />
                        </Stack>
                      </Stack>

                      {/* Show candidate name & job details  */}
                      <Stack direction="column" justifyContent="start" alignItems="start" width="100%">
                        <Typography variant="h6" sx={{ color: theme.palette.text.primary }}>
                          {t("applications.references.landing_page.on_behalf_of")}
                        </Typography>

                        <Stack direction="row" justifyContent="start" alignItems="center" gap={1}>
                          <Iconify icon="hugeicons:user" />
                          <TruncatedTextField
                            text={`${referenceRequest.candidate.first_name} ${referenceRequest.candidate.last_name}`}
                            variant="body2"
                            color={theme.palette.text.secondary}
                            mode="characters"
                            textAlign="left"
                            showMoreEnabled={false}
                            limit={20}
                          />
                        </Stack>


                        <Stack direction="row" justifyContent="start" alignItems="center" gap={1}>
                          <Iconify icon="healthicons:job-status-level" />
                          <TruncatedTextField
                            text={_.capitalize(referenceRequest.candidate.position_title)}
                            variant="body2"
                            color={theme.palette.text.primary}
                            mode="characters"
                            textAlign="left"
                            showMoreEnabled={false}
                            limit={20}
                          />
                        </Stack>

                        <Stack direction="row" justifyContent="start" alignItems="center" gap={1}>
                          <Iconify icon="hugeicons:new-job" />

                          <TruncatedTextField
                            text={_.capitalize(referenceRequest.job.title)}
                            variant="body2"
                            color={theme.palette.text.primary}
                            mode="characters"
                            textAlign="left"
                            showMoreEnabled={false}
                            limit={20}
                          />
                        </Stack>

                      </Stack>

                    </Stack>
                  </Stack>

                  <Divider sx={{ my: 2 }} />

                  <Stepper activeStep={activeStep} orientation="vertical">
                    {STEPS.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel error={isStepInvalid(step.id)} sx={{ py: 0 }}>
                          {step.label}
                        </StepLabel>
                        <StepContent sx={{ textAlign: 'left' }} >
                          <Typography variant="caption">{step.description}</Typography>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Card>
              </Stack>
            </Grid>
          )
        }

        {/* Main content section */}
        {
          !isSubmitted && (
            <Grid item xs={12} xl={8} sx={{ my: 2 }}>

              <Stack sx={{ width: '100%' }} gap={2}>

                {/* title */}
                <Stack sx={{ width: '100%' }}>
                  <Typography variant="h4" sx={{ color: theme.palette.text.primary }} textAlign="left">
                    {renderTitle()}
                  </Typography>
                  <Typography variant="caption" sx={{ color: theme.palette.text.primary }} textAlign="left">
                    {STEPS[activeStep].description}
                  </Typography>
                </Stack>

                <Box sx={{ minHeight: 200 }}>
                  {renderFlowStep()}
                </Box>

                {/* Navigation buttons */}

                <Stack direction="row" justifyContent="space-between" mt={2}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    {t("common.back")}
                  </Button>

                  {renderNextButton()}
                </Stack>
              </Stack>

            </Grid>
          )
        }

        {/* Success message */}
        {
          isSubmitted && (
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Stack sx={{ textAlign: "center", p: 4 }} alignItems="center" justifyContent="center">
                <AnimationContainer height={200} width={200} json={successAnimation} loop={false} />
                <Typography variant="h5">
                  {t("applications.references.landing_page.success_title")}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  {t("applications.references.landing_page.success_message")}
                </Typography>
              </Stack>
            </Grid>
          )
        }

      </Grid>
    </Box >
  );
}