import { useEffect } from 'react';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

import {
  Box,
  Card,
  Stack,
  Button,
  Dialog,
  Typography,
  CardContent,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress
} from '@mui/material';

import { localStorageGetItem } from 'src/utils/local-storage';

import { useTranslate } from 'src/locales';
import { useAppDispatch } from 'src/store/store';
import { useOrgTenant } from 'src/auth/hooks/useOrgTenant';
import { ReferenceOpenStatus } from 'src/services/references/references.types';
import { useMarkReferenceAsViewedMutation, useGetApplicationReferenceByIdQuery } from 'src/services/references/references.service';

import Iconify from 'src/components/iconify';
import DateDisplay from 'src/components/date/date-display';

type Props = {
  open: boolean;
  applicationId: string;
  referenceId: string;
  onClose: VoidFunction;
};

export default function ViewApplicationReferenceModal({
  open,
  applicationId,
  referenceId,
  onClose
}: Props) {
  const { t } = useTranslate();
  const tenant = useOrgTenant();
  const dispatch = useAppDispatch();

  const [markAsViewed] = useMarkReferenceAsViewedMutation();

  const {
    currentData: reference,
    isLoading,
    isError
  } = useGetApplicationReferenceByIdQuery({
    applicationId,
    referenceId
  });

  useEffect(() => {
    if (reference && reference.open_status === ReferenceOpenStatus.NOT_VIEWED) {
      markAsViewed({
        applicationId,
        referenceId
      });
    }
  }, [reference, markAsViewed, applicationId, referenceId]);

  const handleReferenceExport = async () => {
    const toastId = enqueueSnackbar(t('applications.references.export.loading'), {
      variant: 'loading',
      persist: true
    });

    try {

      // get the token from local storage
      const token = localStorageGetItem('access_token', undefined);

      // use fetch instead of rtk (doesn't fully support file download)
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/jobs/applications/${applicationId}/references/${referenceId}/export`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download');
      }

      // get the blob from the response
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', `${referenceId}_reference.pdf`);

      document.body.appendChild(link);
      link.click();

      // cleanup
      link.remove();
      window.URL.revokeObjectURL(url);

      closeSnackbar(toastId);
      enqueueSnackbar(t('applications.references.export.success'), { variant: 'success' });
    }
    catch (error) {
      closeSnackbar(toastId);
      enqueueSnackbar(t('applications.references.export.default_error'), { variant: 'error' });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: { borderRadius: 2 }
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="span">
            {t('applications.references.view_submissions_modal.title')}
          </Typography>

          <Button
            onClick={handleReferenceExport}
            variant="outlined"
            startIcon={<Iconify icon="bx:export" />}
            sx={{
              minWidth: 100,
              borderRadius: 1
            }}
          >
            {t('applications.references.view_submissions_modal.export_reference')}
          </Button>
        </Stack>

      </DialogTitle>

      <DialogContent sx={{ py: 3 }}>
        {isLoading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
            <CircularProgress />
          </Box>
        )}

        {(isError && !isLoading) && (
          <Typography color="error" align="center" sx={{ py: 4 }}>
            {t('applications.references.view_submissions_modal.error_loading_data')}
          </Typography>
        )}

        {
          (!isError && !isLoading) &&
          <>
            {
              reference?.submissions?.length ? (
                <Stack spacing={2} mt={2}>

                  {reference?.referee && (
                    <Card
                      variant="outlined"
                      sx={{
                        mb: 3,
                        borderRadius: 1.5,
                        bgcolor: 'grey.50'
                      }}
                    >
                      <CardContent sx={{ px: 2, pt: 2, pb: 1 }}>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{ fontWeight: 'medium' }}
                        >
                          {t('applications.references.view_submissions_modal.referee')}: {reference.referee.first_name}{' '}{reference.referee.last_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {reference.referee.company_name} | {reference.referee.referee_position}
                        </Typography>

                        <Stack direction="row" spacing={1} justifyContent="space-between" mt={2}>
                          <Stack direction="row" spacing={1} justifyContent="start">
                            <Typography variant="caption" color="text.secondary">
                              {t('applications.references.view_submissions_modal.submitted_on')}:
                            </Typography>
                            <DateDisplay date={reference.submitted_at} variant="caption" color="text.secondary" />
                          </Stack>

                          <Stack direction="row" spacing={1} justifyContent="start">
                            <Typography variant="caption" color="text.secondary">
                              {t('applications.references.view_submissions_modal.ip_address')}:
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {reference.ip_address ? reference.ip_address : '-'}
                            </Typography>
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  )}

                  {reference.submissions.map((submission, index) => (
                    <Card
                      key={submission.id}
                      variant="outlined"
                      sx={{
                        borderRadius: 1.5
                      }}
                    >
                      <CardContent sx={{ p: 2 }}>
                        <Typography
                          variant="subtitle1"
                          color="text.primary"
                          sx={{ mb: 1, fontWeight: 'medium' }}
                        >
                          {submission.question}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ whiteSpace: 'pre-wrap' }}
                        >
                          {submission.answer || t('applications.references.view_submissions_modal.no_answer_provided')}
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Stack>
              ) : (
                <Typography
                  variant="body1"
                  color="text.secondary"
                  align="center"
                  sx={{ py: 4 }}
                >
                  {t('applications.references.view_submissions_modal.no_submissions_found')}
                </Typography>
              )}
          </>
        }
      </DialogContent>

      <DialogActions sx={{ p: 2, borderTop: 1, borderColor: 'divider' }}>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            minWidth: 100,
            borderRadius: 1
          }}
        >
          {t('common.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}