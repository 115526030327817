// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: {
      root: `${ROOTS.AUTH}/register`,
      confirm: `${ROOTS.AUTH}/register/confirm`,
      account: `${ROOTS.AUTH}/register/account`,
      verify: `${ROOTS.AUTH}/register/account/verify`,
      organisation: `${ROOTS.AUTH}/register/organisation`,
      organisationInvites: `${ROOTS.AUTH}/register/organisation-invites`,
      candidate: `${ROOTS.AUTH}/register/candidate`,
      invites: `${ROOTS.AUTH}/register/invites`,
    },
    reset: `${ROOTS.AUTH}/password-reset`,
    accept_invite: `${ROOTS.AUTH}/accept-invite`,
    offline_verification: `${ROOTS.AUTH}/complete-registration`,
    connection_request: `/connection-requests`,
    callbacks: {
      job_adder: `${ROOTS.AUTH}/jobadder/callback`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/activity`,
    account: {
      root: `${ROOTS.DASHBOARD}/account`,
      security: `${ROOTS.DASHBOARD}/account/security`,
      notifications: `${ROOTS.DASHBOARD}/account/notifications`,
      settings: `${ROOTS.DASHBOARD}/account/settings`,
    },
    profile: {
      root: `${ROOTS.DASHBOARD}/profile`,
      documents: `${ROOTS.DASHBOARD}/documents`,
      referees: {
        root: `${ROOTS.DASHBOARD}/referees`,
        detail: (id: string) => `${ROOTS.DASHBOARD}/referees/${id}`,
      },
      edit: `${ROOTS.DASHBOARD}/profile`,
    },
    candidates: {
      root: `${ROOTS.DASHBOARD}/candidates`,
    },
    jobs: {
      root: `${ROOTS.DASHBOARD}/jobs`,
      create: `${ROOTS.DASHBOARD}/jobs/create`,
      create_link: (linkRequestId: string) =>
        `${ROOTS.DASHBOARD}/jobs/create?linkRequestId=${linkRequestId}`,
      edit: (id: string, viewMode: 'view' | 'edit' = 'view') =>
        `${ROOTS.DASHBOARD}/jobs/${id}/editor`,
      detail: (id: string) => `${ROOTS.DASHBOARD}/jobs/${id}`,
      application: (jobId: string, applicationId: string) =>
        `${ROOTS.DASHBOARD}/jobs/${jobId}/applications/${applicationId}`,
    },
    contacts: {
      root: `${ROOTS.DASHBOARD}/contacts`,
    },
    connections: {
      root: `${ROOTS.DASHBOARD}/connections`,
      clients: `${ROOTS.DASHBOARD}/connections/clients`,
      recruiters: `${ROOTS.DASHBOARD}/connections/recruiters`,
    },
    admin: {
      organization: {
        root: `${ROOTS.DASHBOARD}/admin/organisation`,
        company_profile: `${ROOTS.DASHBOARD}/admin/organisation/company-profile`,
        users: `${ROOTS.DASHBOARD}/admin/organisation/users`,
        department: `${ROOTS.DASHBOARD}/admin/organisation/departments`,
        settings: `${ROOTS.DASHBOARD}/admin/organisation/settings`,
        domains: `${ROOTS.DASHBOARD}/admin/organisation/domains`,
      },
      connection_management: {
        root: `${ROOTS.DASHBOARD}/admin/connections`,
        detail: (id: string) => `${ROOTS.DASHBOARD}/admin/connections/${id}`,
      },
      billing: {
        root: `${ROOTS.DASHBOARD}/admin/billing`,
      },
      documents: {
        root: `${ROOTS.DASHBOARD}/admin/documents`,
      },
      integrations: {
        root: `${ROOTS.DASHBOARD}/admin/integrations`,
        detail: {
          configuration: (id: string) =>
            `${ROOTS.DASHBOARD}/admin/integrations/${id}/configuration`,
          activity: (id: string) => `${ROOTS.DASHBOARD}/admin/integrations/${id}/activity`,
        },
      },
    },
    restricted: {
      tenants: {
        root: `${ROOTS.DASHBOARD}/restricted/tenants`,
      },
      accounts: { root: `${ROOTS.DASHBOARD}/restricted/accounts` },
      settings: { root: `${ROOTS.DASHBOARD}/restricted/settings` },
    },
  },
  experience_verification: '/experience-verification',
  job_board: '/job-boards/:board_id/jobs',
};
