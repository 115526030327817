import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Grid,
  Alert,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent
} from "@mui/material";

import { useTranslate } from "src/locales";
import { VisibilityRule } from 'src/services/jobs/jobs.types';
import { useAuthContext } from "src/auth/hooks/useAuthContext";
import {
  useCreateProfileRefereeMutation
} from "src/services/candidates/candidates.service";

import {
  RHFTextField
} from 'src/components/hook-form';

type Props = {
  open: boolean;
  profileId: string;
  onClose: VoidFunction;
};

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  relationship: string;
  position_title: string;
  company_name: string;
  referee_position: string;
  work_experience_id?: string | null;
};

export default function AddRefereeModal({ open, profileId, onClose }: Props) {

  const { t } = useTranslate();

  const authContext = useAuthContext();

  const [createReferee, { isLoading: isSubmitting }] = useCreateProfileRefereeMutation();

  const SubmitRefereeSchema = Yup.object().shape({
    first_name: Yup.string().required(t('validation.required')),
    last_name: Yup.string().required(t('validation.required')),
    email: Yup.string().email(t('validation.email_format')).required(t('validation.required')),
    phone: Yup.string().required(t('validation.required')),
    relationship: Yup.string().required(t('validation.required')),
    position_title: Yup.string().required(t('validation.required')),
    company_name: Yup.string().required(t('validation.required')),
    referee_position: Yup.string().required(t('validation.required')),
    work_experience_id: Yup.string().nullable(),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(SubmitRefereeSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      relationship: '',
      position_title: '',
      company_name: '',
      referee_position: '',
      work_experience_id: null as string | null
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    setError
  } = methods;

  const onSubmit = async (data: FormValues) => {
    try {

      await createReferee({
        profileId,
        data: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          relationship: data.relationship,
          position_title: data.position_title,
          company_name: data.company_name,
          referee_position: data.referee_position,
          visibility_rule: VisibilityRule.PUBLIC
        }
      }).unwrap();

      enqueueSnackbar(t('profile.referees.add_referee_modal.api.success'), { variant: 'success' });
      onClose();
    } catch (error) {

      if (error.status === 422) {
        const {errors} = error.data;
        Object.keys(errors).forEach((key) => {
          setError(key as keyof FormValues, {
            type: 'manual',
            message: errors[key][0]
          });
        });
      }
      else {
        enqueueSnackbar(t('profile.referees.add_referee_modal.api.default_error'), { variant: 'error' });
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {t('profile.referees.add_referee_modal.title')}
      </DialogTitle>
      <DialogContent>

        <FormProvider {...methods}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Alert severity="info">
                <Typography variant="subtitle2">
                  {t('profile.referees.add_referee_modal.data_share_title')}
                </Typography>
                <Typography variant="body2">
                  {t('profile.referees.add_referee_modal.data_share_notice')}
                </Typography>
              </Alert>
            </Grid>

            <Grid item xs={6}>
              <RHFTextField
                name="first_name"
                label={t('applications.references.submit_referee_modal.first_name')}
              />
            </Grid>
            <Grid item xs={6}>
              <RHFTextField
                name="last_name"
                label={t('applications.references.submit_referee_modal.last_name')}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                name="email"
                label={t('applications.references.submit_referee_modal.email')}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                name="phone"
                label={t('applications.references.submit_referee_modal.phone')}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                name="relationship"
                label={t('applications.references.submit_referee_modal.relationship')}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                name="position_title"
                label={t('applications.references.submit_referee_modal.position_title')}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                name="company_name"
                label={t('applications.references.submit_referee_modal.company_name')}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                name="referee_position"
                label={t('applications.references.submit_referee_modal.referee_position')}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {t('common.cancel')}
        </Button>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isSubmitting}
          loading={isSubmitting}
          color="primary"
        >
          {t('common.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}